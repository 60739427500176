import * as React from "react";
import { RouteComponentProps, useLocation, useParams } from "react-router-dom";
import { InstanceCreate } from "./InstanceCreate";
import { InstancePortal } from "./InstancePortal";
import { InstanceHome } from "./InstanceHome";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { UserProfilePortal } from "../user/UserProfilePortal";
import { SherpaFooter } from "../../components/layout/SherpaFooter";

export const instanceRoutes = [
    {
        path: "/instance/create",
        content: (props: any) => <InstanceCreate/>,
        header: () => <SherpaHeader headerType="Home"/>
    },
    {
        path: "/instance/:instanceId/admin",
        content: (props: RouteComponentProps) => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <InstancePortal instanceId={instanceId}/>;
        },
        breadcrumbs: (props: RouteComponentProps) => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Admin Portal", href: `#/instance/${instanceId}/admin`}
            ]}/>;
        },
        header: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaHeader headerType="Other" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaFooter variant="AdminPortal" entityId={instanceId}/>;
        }
    },
    {
        path: "/instance/:instanceId/home",
        content: (props: RouteComponentProps) => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <InstanceHome id={instanceId}/>;
        },
        contentClass: "instance-home-no-padding",
        header: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaHeader headerType="InstanceHome" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaFooter variant="InstanceHome" entityId={instanceId}/>;
        }
    },
    {
        path: "/instance/:instanceId/myProfile",
        breadcrumbs: (props: RouteComponentProps) => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Home", href: `#/instance/${instanceId}/home` },
                { text: "My Profile", href: `#/instance/${instanceId}/myProfile` }
            ]}/>;
        },
        content: (props: RouteComponentProps) => {
            let { instanceId } = useParams<{ instanceId: string }>();
            let activeTabId = "myFavorites";
            const params = new URLSearchParams(useLocation().search);
            if (params.has("tab")) {
                activeTabId = params.get("tab");
            }
            return <UserProfilePortal
                id={instanceId}
                activeTabId={activeTabId}
            />;
        },
        header: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaHeader headerType="Other" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaFooter variant="AdminPortal" entityId={instanceId}/>;
        }
    }
];