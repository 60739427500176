import { Link } from "@amzn/awsui-components-react";
import React = require("react");

export const IdentityLink = (props: {
    id: string;
    name?: string;
}) => {
    return (
        <Link
            external
            target="_blank"
            href={`https://phonetool.amazon.com/users/${props.id}`}>
            {props.name ? props.name : props.id}
        </Link>
    );
};

export const TeamIdentityLink = (props: {
    id: string;
    name?: string;
}) => {
    return (
        <Link
            external
            target="_blank"
            href={`https://permissions.amazon.com/a/team/${props.id}`}
        >
            {props.name ? props.name : props.id}
        </Link>
    );
};