import * as React from "react";
import { Navigation } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    ExpandableSection,
    Grid,
    Header,
    Icon,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import { CommonNavigationModal } from "./CommonNavigationModal";
import { AddNavItemContent } from "./modal-content/AddNavItemContent";
import { NavigationModel } from "../../model/navigation-model";
import { EditNavItemContent } from "./modal-content/EditNavItemContent";
import { LegalOnlyBadgeComp } from "../common/LegalOnlyTag";

export namespace NavTabComp {
    const TextOnlyComp = <Box
        margin={{ "left" : "m" }}
        variant="awsui-key-label"
    ><em>Text only</em></Box>;

    export const Detail = (props: {
        state: NavigationModel.UpdateNavigationState;
        navTab: Navigation.NavTab;
    }) => {
        const addNavItemState = NavigationModel.AddNavItemState.use({
            parentTab: props.navTab
        });
        const [modalAction, setModalAction] = React.useState<"ADD" | "EDIT" | undefined>();
        const [expanded, setExpanded] = React.useState<boolean>(true);
        const [expandFlag_1, setExpandFlag_1] = React.useState<boolean>(false);
        const [expandFlag_2, setExpandFlag_2] = React.useState<boolean>(false);

        React.useEffect(() => {
            let currentExpanded = expanded;
            if (!!expandFlag_1) {
                currentExpanded = !currentExpanded;
                setExpandFlag_1(false);
            }
            if (!!expandFlag_2) {
                currentExpanded = !currentExpanded;
                setExpandFlag_2(false);
            }
            setExpanded(currentExpanded);
        }, [expandFlag_1, expandFlag_2]);

        return (
            <React.Fragment>
                {modalAction === "ADD" &&
                    <CommonNavigationModal
                        header="Add Item"
                        loading={false}
                        child={<AddNavItemContent state={addNavItemState}/>}
                        onComplete={() => {
                            props.state.addNavItem(addNavItemState);
                            setModalAction(undefined);
                            addNavItemState.reset();
                            setExpanded(true);
                        }}
                        onCancel={() => {
                            setModalAction(undefined);
                            addNavItemState.reset();
                        }}
                    />
                }
                <ExpandableSection
                    expanded={expanded}
                    onChange={() => setExpandFlag_1(true)}
                    variant="container"
                    header={<Header
                        variant="h3"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <span
                                    title="Navigation Tab Menu"
                                    onClick={() => setExpandFlag_2(true)}
                                >
                                    <ButtonDropdown
                                        variant="normal"
                                        items={[
                                            NavTabDetailButtonDropdowns.Add,
                                            NavTabDetailButtonDropdowns.Remove,
                                            NavTabDetailButtonDropdowns.Reorder,
                                        ]}
                                        onItemClick={(e) => {
                                            switch (e.detail.id) {
                                                case NavTabDetailButtonDropdowns.Add.id:
                                                    addNavItemState.parentTab.setValue(props.navTab);
                                                    addNavItemState.parentGroup.setValue(undefined);
                                                    addNavItemState.parentLink.setValue(undefined);
                                                    setModalAction("ADD");
                                                    break;
                                                case NavTabDetailButtonDropdowns.Remove.id:
                                                    props.state.removeNavItem(props.navTab);
                                                    break;
                                                case NavTabDetailButtonDropdowns.MoveDown.id:
                                                    props.state.reorder({
                                                            navTab: props.navTab,
                                                        }, "Down"
                                                    );
                                                    break;
                                                case NavTabDetailButtonDropdowns.MoveUp.id:
                                                    props.state.reorder({
                                                            navTab: props.navTab,
                                                        }, "Up"
                                                    );
                                                    break;
                                            }
                                        }}>Actions
                                    </ButtonDropdown>
                                </span>
                            </SpaceBetween>
                        }>
                        <p style={{ margin: "0", padding: "0", float: "left" }}>
                            {props.navTab.name} {props.navTab.legalOnly ? LegalOnlyBadgeComp : ""}
                        </p>
                    </Header>}>
                        <Box padding={{left: "l", right: "l"}}>
                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                                <strong>Name</strong>
                                <strong>URL</strong>
                                <strong>Action</strong>
                            </Grid>
                        </Box>
                        { props.navTab.items.map(group =>
                            <NavGroupDetail
                                state={props.state}
                                navGroup={group}
                                navTab={props.navTab}
                            />
                        )}
                </ExpandableSection>
            </React.Fragment>
        );
    };

    const NavGroupDetail = (props: {
        state: NavigationModel.UpdateNavigationState
        navTab: Navigation.NavTab;
        navGroup: Navigation.NavGroup;
    }) => {
        const addNavItemState = NavigationModel.AddNavItemState.use({
            parentTab: props.navTab,
            parentGroup: props.navGroup
        });
        const editNavItemState = NavigationModel.EditNavItemState.use({
            isLink: !!props.navGroup.url,
            navTab: props.navTab,
            navGroup: props.navGroup
        });

        const [modalAction, setModalAction] = React.useState<"ADD" | "EDIT" | undefined>();
        const [expanded, setExpanded] = React.useState<boolean>(true);

        React.useEffect(() => {
            addNavItemState.parentTab.setValue(props.navTab);
            addNavItemState.parentGroup.setValue(props.navGroup);
            editNavItemState.tabField.setValue(props.navTab);
            editNavItemState.navGroupField.setValue(props.navGroup);
        }, [props.navTab, props.navGroup]);

        return (
            <React.Fragment>
                {modalAction === "ADD" && (
                    <CommonNavigationModal
                        header="Add Item"
                        loading={false}
                        child={<AddNavItemContent state={addNavItemState}/>}
                        onComplete={() => {
                            props.state.addNavItem(addNavItemState);
                            setModalAction(undefined);
                            addNavItemState.reset();
                            setExpanded(true);
                        }}
                        onCancel={() => {
                            setModalAction(undefined);
                            addNavItemState.reset();
                        }}
                    />
                )}
                {modalAction === "EDIT" && (
                    <CommonNavigationModal
                        header="Edit Item"
                        loading={false}
                        child={<EditNavItemContent state={editNavItemState}/>}
                        onComplete={() => {
                            props.state.editNavItem(editNavItemState);
                            setModalAction(undefined);
                            editNavItemState.reset();
                        }}
                        onCancel={() => {
                            setModalAction(undefined);
                            editNavItemState.reset();
                        }}
                    />
                )}
                <Box padding={{left: "l", right: "l"}}>
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <div
                            style={{ cursor: props.navGroup.nested.length > 0 ? "pointer" : "default" }}
                            onClick={() => props.navGroup.nested.length > 0 && setExpanded(!expanded)}>
                            <span style={{ opacity: props.navGroup.nested.length > 0 ? "1" : "0.3" }}>
                                {expanded ? <Button
                                    variant="inline-icon"
                                    iconName="caret-down-filled"
                                /> : <Button
                                    iconName="caret-right-filled"
                                    variant="inline-icon"
                                />}
                            </span>
                            {props.navGroup.name}
                        </div>
                        {!props.navGroup.url && TextOnlyComp}
                        {!!props.navGroup.url && <div>{props.navGroup.url}</div>}
                        <div>
                            <ButtonDropdown
                                variant="primary"
                                items={[
                                    NavTabDetailButtonDropdowns.Add,
                                    NavTabDetailButtonDropdowns.Edit,
                                    NavTabDetailButtonDropdowns.Remove,
                                    NavTabDetailButtonDropdowns.Reorder,
                                ]}
                                onItemClick={(e) => {
                                    switch (e.detail.id) {
                                        case NavTabDetailButtonDropdowns.Add.id:
                                            addNavItemState.parentTab.setValue(props.navTab);
                                            addNavItemState.parentGroup.setValue(props.navGroup);
                                            addNavItemState.parentLink.setValue(undefined);
                                            setModalAction("ADD");
                                            break;
                                        case NavTabDetailButtonDropdowns.Edit.id:
                                            editNavItemState.tabField.setValue(props.navTab);
                                            editNavItemState.navGroupField.setValue(props.navGroup);
                                            editNavItemState.navLinkField.setValue(undefined);
                                            editNavItemState.navSubLinkField.setValue(undefined);
                                            setModalAction("EDIT");
                                            break;
                                        case NavTabDetailButtonDropdowns.Remove.id:
                                            props.state.removeNavItem(props.navTab, props.navGroup);
                                            break;
                                        case NavTabDetailButtonDropdowns.MoveDown.id:
                                            props.state.reorder({
                                                    navTab: props.navTab,
                                                    navGroup: props.navGroup
                                                }, "Down"
                                            );
                                            break;
                                        case NavTabDetailButtonDropdowns.MoveUp.id:
                                            props.state.reorder({
                                                    navTab: props.navTab,
                                                    navGroup: props.navGroup
                                                }, "Up"
                                            );
                                            break;
                                    }
                                }}><Icon name="menu"/>
                            </ButtonDropdown>
                        </div>
                    </Grid>
                    { expanded && props.navGroup.nested.map((NavLink) =>
                        <NavLinkDetail
                            state={props.state}
                            navTab={props.navTab}
                            navGroup={props.navGroup}
                            navLink={NavLink}
                        />
                    ) }
                </Box>
            </React.Fragment>
        );
    };

    const NavLinkDetail = (props: {
        state: NavigationModel.UpdateNavigationState
        navTab: Navigation.NavTab;
        navGroup: Navigation.NavGroup;
        navLink: Navigation.NavLink;
    }) => {
        const addNavItemState = NavigationModel.AddNavItemState.use({
            parentTab: props.navTab,
            parentGroup: props.navGroup,
            parentLink: props.navLink
        });
        const editNavItemState = NavigationModel.EditNavItemState.use({
            isLink: !!props.navLink.url,
            navTab: props.navTab,
            navGroup: props.navGroup,
            navLink: props.navLink
        });

        const [modalAction, setModalAction] = React.useState<"ADD" | "EDIT" | undefined>();
        const [expanded, setExpanded] = React.useState<boolean>(true);

        React.useEffect(() => {
            editNavItemState.tabField.setValue(props.navTab);
            editNavItemState.navGroupField.setValue(props.navGroup);
            editNavItemState.navLinkField.setValue(props.navLink);
            addNavItemState.parentTab.setValue(props.navTab);
            addNavItemState.parentGroup.setValue(props.navGroup);
            addNavItemState.parentLink.setValue(props.navLink);
        }, [props.navTab, props.navGroup, props.navLink]);

        return (
            <React.Fragment>
                {modalAction === "ADD" && (
                    <CommonNavigationModal
                        header="Add Item"
                        loading={false}
                        child={<AddNavItemContent state={addNavItemState}/>}
                        onComplete={() => {
                            props.state.addNavItem(addNavItemState);
                            setModalAction(undefined);
                            addNavItemState.reset();
                            setExpanded(true);
                        }}
                        onCancel={() => {
                            setModalAction(undefined);
                            addNavItemState.reset();
                        }}
                    />
                )}
                {modalAction === "EDIT" && (
                    <CommonNavigationModal
                        header="Edit Item"
                        loading={false}
                        child={<EditNavItemContent state={editNavItemState}/>}
                        onComplete={() => {
                            props.state.editNavItem(editNavItemState);
                            setModalAction(undefined);
                            editNavItemState.reset();
                        }}
                        onCancel={() => {
                            setModalAction(undefined);
                            editNavItemState.reset();
                        }}
                    />
                )}
                <Box padding={{left: "l", right: "l"}}>
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <div
                            style={{ cursor: props.navLink.nested?.length > 0 ? "pointer" : "default" }}
                            onClick={() => props.navLink.nested?.length > 0 && setExpanded(!expanded)}>
                            <span style={{ opacity: props.navLink.nested?.length > 0 ? "1" : "0.3" }}>
                                {expanded ? <Button
                                    variant="inline-icon"
                                    iconName="caret-down-filled"
                                /> : <Button
                                    iconName="caret-right-filled"
                                    variant="inline-icon"
                                />}
                            </span>
                            {props.navLink.name}
                        </div>
                        {!props.navLink.url && TextOnlyComp}
                        {!!props.navLink.url && <div>{props.navLink.url}</div>}
                        <div>
                            <ButtonDropdown
                                variant="normal"
                                items={[
                                    NavTabDetailButtonDropdowns.Add,
                                    NavTabDetailButtonDropdowns.Edit,
                                    NavTabDetailButtonDropdowns.Remove,
                                    NavTabDetailButtonDropdowns.Reorder,
                                ]}
                                onItemClick={(e) => {
                                    switch (e.detail.id) {
                                        case NavTabDetailButtonDropdowns.Add.id:
                                            addNavItemState.parentTab.setValue(props.navTab);
                                            addNavItemState.parentGroup.setValue(props.navGroup);
                                            addNavItemState.parentLink.setValue(props.navLink);
                                            setModalAction("ADD");
                                            break;
                                        case NavTabDetailButtonDropdowns.Edit.id:
                                            editNavItemState.tabField.setValue(props.navTab);
                                            editNavItemState.navGroupField.setValue(props.navGroup);
                                            editNavItemState.navLinkField.setValue(props.navLink);
                                            editNavItemState.navSubLinkField.setValue(undefined);
                                            setModalAction("EDIT");
                                            break;
                                        case NavTabDetailButtonDropdowns.Remove.id:
                                            props.state.removeNavItem(props.navTab, props.navGroup, props.navLink);
                                            break;
                                        case NavTabDetailButtonDropdowns.MoveDown.id:
                                            props.state.reorder({
                                                    navTab: props.navTab,
                                                    navGroup: props.navGroup,
                                                    navLink: props.navLink
                                                }, "Down"
                                            );
                                            break;
                                        case NavTabDetailButtonDropdowns.MoveUp.id:
                                            props.state.reorder({
                                                    navTab: props.navTab,
                                                    navGroup: props.navGroup,
                                                    navLink: props.navLink
                                                }, "Up"
                                            );
                                            break;
                                    }
                                }}><Icon name="menu"/>
                            </ButtonDropdown>
                        </div>
                    </Grid>
                    { expanded && props.navLink.nested?.map((navSubLink) => (
                        <NavSubLinkDetail
                            state={props.state}
                            navTab={props.navTab}
                            navGroup={props.navGroup}
                            navLink={props.navLink}
                            navSubLink={navSubLink}
                        />
                    )) }
                </Box>
            </React.Fragment>
        );
    };

    const NavSubLinkDetail = (props: {
        state: NavigationModel.UpdateNavigationState
        navTab: Navigation.NavTab;
        navGroup: Navigation.NavGroup;
        navLink: Navigation.NavLink;
        navSubLink: Navigation.NavSubLink;
    }) => {
        const [modalAction, setModalAction] = React.useState<"EDIT" | undefined>();
        const editNavItemState = NavigationModel.EditNavItemState.use({
            isLink: !!props.navSubLink.url,
            navTab: props.navTab,
            navGroup: props.navGroup,
            navLink: props.navLink,
            navSubLink: props.navSubLink
        });

        React.useEffect(() => {
            editNavItemState.tabField.setValue(props.navTab);
            editNavItemState.navGroupField.setValue(props.navGroup);
            editNavItemState.navLinkField.setValue(props.navLink);
            editNavItemState.navSubLinkField.setValue(props.navSubLink);
        }, [props.navTab, props.navGroup, props.navLink, props.navSubLink]);

        return (
            <React.Fragment>
                {modalAction === "EDIT" && (
                    <CommonNavigationModal
                        header="Edit Item"
                        loading={false}
                        child={<EditNavItemContent state={editNavItemState}/>}
                        onComplete={() => {
                            props.state.editNavItem(editNavItemState);
                            setModalAction(undefined);
                            editNavItemState.reset();
                        }}
                        onCancel={() => {
                            setModalAction(undefined);
                            editNavItemState.reset();
                        }}
                    />
                )}
                <Box padding={{left: "l", right: "l"}}>
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <Box padding={{left: "xxl"}}>{props.navSubLink.name}</Box>
                        {props.navSubLink.url}
                        <div>
                            <ButtonDropdown
                                variant="normal"
                                items={[
                                    NavTabDetailButtonDropdowns.Edit,
                                    NavTabDetailButtonDropdowns.Remove,
                                    NavTabDetailButtonDropdowns.Reorder,
                                ]}
                                onItemClick={(e) => {
                                    switch (e.detail.id) {
                                        case NavTabDetailButtonDropdowns.Edit.id:
                                            editNavItemState.tabField.setValue(props.navTab);
                                            editNavItemState.navGroupField.setValue(props.navGroup);
                                            editNavItemState.navLinkField.setValue(props.navLink);
                                            editNavItemState.navSubLinkField.setValue(props.navSubLink);
                                            setModalAction("EDIT");
                                            break;
                                        case NavTabDetailButtonDropdowns.Remove.id:
                                            props.state.removeNavItem(
                                                props.navTab,
                                                props.navGroup,
                                                props.navLink,
                                                props.navSubLink
                                            );
                                            break;
                                        case NavTabDetailButtonDropdowns.MoveDown.id:
                                            props.state.reorder({
                                                    navTab: props.navTab,
                                                    navGroup: props.navGroup,
                                                    navLink: props.navLink,
                                                    navSubLink: props.navSubLink
                                                }, "Down"
                                            );
                                            break;
                                        case NavTabDetailButtonDropdowns.MoveUp.id:
                                            props.state.reorder({
                                                    navTab: props.navTab,
                                                    navGroup: props.navGroup,
                                                    navLink: props.navLink,
                                                    navSubLink: props.navSubLink
                                                }, "Up"
                                            );
                                            break;
                                    }
                                }}><Icon name="menu"/>
                            </ButtonDropdown>
                        </div>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    };
}

namespace NavTabDetailButtonDropdowns {
    export const Add = Builder<ButtonDropdownProps.Item>()
        .id("Add")
        .text("Add Item")
        .build();
    export const Edit = Builder<ButtonDropdownProps.Item>()
        .id("Edit")
        .text("Edit")
        .build();
    export const Remove = Builder<ButtonDropdownProps.Item>()
        .id("Delete")
        .text("Delete")
        .build();
    export const MoveUp = Builder<ButtonDropdownProps.Item>()
        .id("MoveUp")
        .text("Move up")
        .build();
    export const MoveDown = Builder<ButtonDropdownProps.Item>()
        .id("MoveDown")
        .text("Move down")
        .build();
    export const Reorder = Builder<ButtonDropdownProps.ItemGroup>()
        .id("Re-order")
        .text("Re-order")
        .items([
            MoveUp,
            MoveDown
        ])
        .build();
}