import * as React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { PageLive } from "../page/PageLive";

export const sharedPageRoutes = [
    {
        path: "/shared-page/:instanceId/:pageId/live",
        content: (props: RouteComponentProps) => {
            let { instanceId, pageId } = useParams<{ instanceId: string, pageId: string }>();
            return <PageLive id={pageId} />;
        },
        header: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaHeader headerType="LivePage" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaFooter variant="LivePage" entityId={instanceId}/>;
        }
    }
];