import { Builder } from "builder-pattern";
import * as React from "react";
import * as Polaris from "@amzn/awsui-components-react";
import { GeneralEntity } from "@amzn/ask-legal-domain";
import { useAPI } from "./api-hook";
import { useEffect } from "react";
import { CommonPolarisFactory } from "../factory/polaris/common-polaris-factory";
import { i18n } from "../i18n/propertyFilteringI18nString";
import { APIResponse } from "../api/common";

export function useLoadingCollection<T extends GeneralEntity, I>(
    api: (input: I) => Promise<APIResponse<T[]>>,
    input: I,
    options: {
        columnDefinitions: Polaris.TableProps.ColumnDefinition<any>[],
        defaultTablePreferences: Polaris.CollectionPreferencesProps.Preferences<any>;
        pageSizeSelectionOptions: Polaris.CollectionPreferencesProps.PageSizeOption[];
        selectionType?: "multi" | "single" | "none"
        empty?: JSX.Element;
        header?: JSX.Element;
    },
    filter?: {
        filteringProperties: Polaris.PropertyFilterProps.FilteringProperty[];
        toOption: (items: T[]) => { propertyKey: string, value: string }[];
        initialFilter?: { propertyKey: string, value: string }[];
    }
) {
    const apiRunner = useAPI(api);
    useEffect(() => {
        apiRunner.submitRun(input);
    }, [JSON.stringify(input)]);

    const [items, setItems] = React.useState<T[]>([]);
    const [selectedItems, setSelectedItems] = React.useState<T[]>([]);

    const pageSizeSelection = CommonPolarisFactory.Table.PageSizeSelection.toPageSizeSelection(
        options.pageSizeSelectionOptions
    );

    const visibleContentPreference =
        CommonPolarisFactory.Table.VisibleContentPreference.toVisibleContentPreference(
            options.columnDefinitions
        );

    const collectionProps = useCollectionProps({
        defaultPreferences: options.defaultTablePreferences,
        pageSizePreference: pageSizeSelection,
        visibleContentPreference: visibleContentPreference
    });

    let propertyFilterProps: Polaris.PropertyFilterProps = undefined;
    if (filter) {
        propertyFilterProps = filter.initialFilter ?
            usePropertyFilter({
                allItems: items,
                toOption: filter.toOption,
                filteringProperties: filter.filteringProperties,
                initialFilter: filter.initialFilter.map(e =>
                    Builder<Polaris.PropertyFilterProps.Token>()
                        .propertyKey(e.propertyKey)
                        .value(e.value)
                        .operator("=")
                        .build())
            }, {}) :
            usePropertyFilter({
                allItems: items,
                toOption: filter.toOption,
                filteringProperties: filter.filteringProperties
            },
        {});
    }

    useEffect(() => {
        if (apiRunner.status === "Succeeded") {
            // to address some weird bug that sometimes status is succeeded, but output is empty
            if (!apiRunner.data.output) {
                apiRunner.reload();
                return;
            }
            setItems(apiRunner.data.output);
            setSelectedItems([]);
            collectionProps.pagination.setItemsCount(apiRunner.data.output.length);
        }
    }, [apiRunner.status]);

    const props = Builder<Polaris.TableProps>()
        .items(items)
        .columnDefinitions(options.columnDefinitions)
        .selectionType(options.selectionType && options.selectionType !== "none" ? options.selectionType : undefined)
        .selectedItems(selectedItems)
        .onSelectionChange(e => setSelectedItems(e.detail.selectedItems))
        .preferences(<Polaris.CollectionPreferences {...collectionProps.preferences}/>)
        .pagination(<Polaris.Pagination {...collectionProps.pagination.pagniation}/>)
        .visibleColumns(collectionProps.preferences.preferences.visibleContent)
        .loading(apiRunner.status === "Running")
        .loadingText("Loading...")
        .filter(propertyFilterProps ? <Polaris.PropertyFilter {...propertyFilterProps}/> : null)
        .empty(options.empty ? options.empty : null)
        .header(options.header ? options.header : null)
        .build();
    return {
        props,
        apiRunner,
    };
}

export function useCollectionProps(props: {
    defaultPreferences: Polaris.CollectionPreferencesProps.Preferences<any>;
    pageSizePreference: Polaris.CollectionPreferencesProps.PageSizePreference;
    visibleContentPreference: Polaris.CollectionPreferencesProps.VisibleContentPreference;
}) {
    const [_preferences, setPreferences] = React.useState(props.defaultPreferences);

    const pagination = useTablePagination(0);

    useEffect(() => {
        pagination.setPageSize(_preferences.pageSize);
    }, [_preferences.pageSize]);

    const preferences = Builder<Polaris.CollectionPreferencesProps>()
        .cancelLabel("Cancel")
        .confirmLabel("Confirm")
        .title("Table Preferences")
        .preferences(_preferences)
        .pageSizePreference(props.pageSizePreference)
        .visibleContentPreference(props.visibleContentPreference)
        .onConfirm(({ detail }) => setPreferences(detail))
        .build();

    return {
        preferences,
        pagination,
    };
}

export function useTablePagination(
    initPageSize: number,
    initial: Polaris.PaginationProps = Builder<Polaris.PaginationProps>().pagesCount(1).build()
) {
    const [itemsCount, setItemsCount] = React.useState(0);
    const [props, setProps] = React.useState<Polaris.PaginationProps>(initial);
    const [pageSize, setPageSize] = React.useState<number>(initPageSize);

    const setCurrentPageIndex = (pageIndex: number) => {
        setProps((prev) => Builder(prev).currentPageIndex(pageIndex).build());
    };

    const calculatePageCount = () => {
        setProps((prev) => {
            const pagesCount = Math.floor(
                (itemsCount + pageSize - 1) / pageSize
            );
            return Builder(prev).pagesCount(pagesCount).build();
        });
    };

    useEffect(() => {
        calculatePageCount();
    }, [pageSize, itemsCount]);

    const pagniation = Builder<Polaris.PaginationProps>(props)
        .onNextPageClick(e => setCurrentPageIndex(e.detail.requestedPageIndex))
        .onPreviousPageClick(e => setCurrentPageIndex(e.detail.requestedPageIndex))
        .build();
    return {
        pagniation,
        setPageSize,
        setItemsCount
    };
}

export function usePropertyFilter<T>(props: {
    allItems: T[];
    filteringProperties: Polaris.PropertyFilterProps.FilteringProperty[];
    toOption: (items: T[]) => { propertyKey: string, value: string }[];
    initialFilter?: Polaris.PropertyFilterProps.Token[];
}, optional?: {
    hideOperations?: boolean;
}): Polaris.PropertyFilterProps {
    const [query, setQuery] = React.useState<Polaris.PropertyFilterProps.Query>({
        tokens: props.initialFilter ? props.initialFilter : [],
        operation: "and"
    });

    const filterPropsBuilder = Builder<Polaris.PropertyFilterProps>()
        .query(query)
        .onChange(e => setQuery(e.detail))
        .filteringOptions(props.toOption(props.allItems))
        .i18nStrings(i18n)
        .filteringProperties(props.filteringProperties)
        .hideOperations(true);
    if (optional) {
        if (!!optional.hideOperations) filterPropsBuilder.hideOperations(optional.hideOperations);
    }
    return filterPropsBuilder.build();
}