import { Spinner } from "@amzn/awsui-components-react";
import { Hub } from "@aws-amplify/core";
import * as React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { instanceRoutes } from "./pages/instance";
import { commonRoutes } from "./pages/common";
import { pageLibraryRoutes } from "./pages/page-library";
import { pageRoutes } from "./pages/page";
import { AskLegalLayout } from "./components/layout/AskLegalLayout";
import { searchRoutes } from "./pages/search";
import { recommendationRoutes } from "./pages/recommendation";
import { AppContext } from "./setup/context";
import { CookiesProvider } from "react-cookie";
import { decisionTreeRoutes } from "./pages/decision-tree";
import { legalContactRoutes } from "./pages/legal-contact";
import { redirectRoutes } from "./pages/redirects";
import { sharedPageRoutes } from "./pages/shared-page";
import "./styles/component/layout/AskLegalLayout.scss";

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

const routes: {
    path: string,
    content: (props: any) => JSX.Element,
    header: () => JSX.Element,
    footer?: () => JSX.Element,
    breadcrumbs?: (props: any) => JSX.Element,
    contentClass?: string,
}[] = [
    ...commonRoutes,
    ...instanceRoutes,
    ...pageLibraryRoutes,
    ...pageRoutes,
    ...searchRoutes,
    ...recommendationRoutes,
    ...decisionTreeRoutes,
    ...legalContactRoutes,
    ...sharedPageRoutes,
    ...redirectRoutes
];

enum AuthStatus {
    Authenticated,
    UnAuthenticated,
    Error,
}

export const App = () => {
    const context = React.useContext(AppContext);
    const [authStatus, setAuthStatus] = React.useState<AuthStatus>(AuthStatus.UnAuthenticated);
    const init = async () => {
        try {
            Hub.listen("auth", (data) => {
                switch (data.payload.event) {
                    case "signIn":
                        console.log("receive signIn event");
                        onSignedIn();
                        break;
                    case "signIn_failure":
                        console.log(
                            `receive signIn failed event with message ${data.payload.message}`
                        );
                        signInFailed(data.payload.message);
                        break;
                    default:
                        break;
                }
            });
            await context.init();
            /* Why sleeps 500ms here?
            * This is because we want to wait 500ms to let browser wait the return tokens from Auth,
            * otherwise the browser will re-trigger another sign-in process (because the browser
            * has not get the tokens yet, so browser thinks the user has not yet signed in),
            * and the re-triggered sign-in process will cause unexpected error in Firefox and Safari
            */
            await sleep(500);
            await context.currentUser();
            await onSignedIn();
        } catch (err) {
            console.warn(err);
            if (!window.location.href.includes("code") && !window.location.href.includes("state")) {
                localStorage.setItem("OrignalURLHashBeforeAuth", window.location.href);
            }
            await context.signIn();
        }
    };

    const onSignedIn = async () => {
        await context.getIdentity();
        setAuthStatus(AuthStatus.Authenticated);
        if (localStorage.getItem("OrignalURLHashBeforeAuth")) {
            window.location.href = localStorage.getItem("OrignalURLHashBeforeAuth");
            localStorage.setItem("OrignalURLHashBeforeAuth", "");
        }
    };

    const signInFailed = async (message: string) => {
        console.error(message);
        setAuthStatus(AuthStatus.Error);
    };

    React.useEffect(() => {
        init();
    }, []);

    const content = () => {
        return routes.map((route) =>
            <Route exact path={route.path} render={(props) =>
                <div className="awsui">
                    <AskLegalLayout
                        header={<route.header/>}
                        content={
                            <div className={route.contentClass ? route.contentClass : "sherpa-content"}>
                                {!!route.breadcrumbs ? <route.breadcrumbs {...props}/> : null}
                                <route.content {...props}/>
                            </div>
                        }
                        footer={!!route.footer ? <route.footer/> : null}
                    />
                </div>
            } />
        );
    };

    /**
     * Intercept user confirmations and track events for history forward/backward
     * On failed confirmation revert to old path
     */
    const getUserConfirmation = (payload: string, callback: (ok: boolean) => void) => {
        const { action, message } = JSON.parse(payload);
        const confirmed = window.confirm(message);
        callback(confirmed);
        // POP action is called for history backward & forward operations
        // Forward operation will atleast be blocked to transitioning page resources
        // for now. URL change still exists. Needs a fix for forward url change.
        if (!confirmed && action === "POP") {
            // push url forward if user tries to navigate back in history
            window.history.forward();
        }
    };

    return (
        <>
            {authStatus === AuthStatus.UnAuthenticated && (
                <span
                    className="awsui-util-status-inactive vertical-center horizontal-center"
                    style={{ paddingTop: "25px" }}
                >
                    <Spinner size="large"/> Loading Resources
                </span>
            )}
            {authStatus === AuthStatus.Error && (
                <div>
                    Failed to load. Please try to refresh the page. If the issue still
                    persists after multiple tries. Please contact altar-dev@amazon.com for
                    support.
                </div>
            )}
            {authStatus === AuthStatus.Authenticated && (
                <CookiesProvider>
                    <AppContext.Provider value={context}>
                        <HashRouter getUserConfirmation={getUserConfirmation}>
                            <Switch>
                                {content()}
                            </Switch>
                        </HashRouter>
                    </AppContext.Provider>
                </CookiesProvider>
            )}
        </>
    );
};