import {
    AdvancedList,
    FieldDefinition,
    FieldDefinitionDataType,
    Variable
} from "@amzn/altar-sds-client";
import {
    Action,
    Condition,
    FieldConfiguration,
    Workflow
} from "@amzn/ask-legal-domain";
import {
    FormField,
    FormFieldProps,
    Header,
    Input,
    InputProps,
    SelectProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { StaticListSelect } from "../../../../common/StaticListSelect";
import { UIField } from "../../../../common/UIField";
import { EmailNotification } from "./EmailNotification";

export const ItemDateCadenceReached = (props: {
    advancedList: AdvancedList;
    variables: Variable[];
    fieldConfigurations?: FieldConfiguration.Record;
    value?: Workflow.Payload;
    onUpdated?: (value: Workflow.Payload) => void;
}) => {
    const condition = props.value?.condition as Condition.ItemDateFieldValueCadenceReached;
    const [formFieldWorkflowName, setFormFieldWorkflowName] = React.useState<FormFieldProps>({});
    const [workflowName, setWorkflowName] = React.useState<string>(props.value?.name ?? "");
    const [variableList, setVariableList] = React.useState<Variable[]>([]);

    const [formField_beginDate, setFormField_beginDate] = React.useState<FormFieldProps>({});
    const [selectedField, setSelectedField] = React.useState<FieldDefinition | undefined>(
        props.advancedList.fieldDefinitions?.find((field) => field.fieldKey === condition?.beginDateVariable.fieldKey) ??
            undefined
    );

    const [formField_intervalInDays, setFormField_intervalInDays] = React.useState<FormFieldProps>({});
    const [input_intervalInDays, setInput_intervalInDays] = React.useState<Pick<InputProps, "value">>({
        value: (condition?.interval || 0).toString()
    });

    const [emailNotification, setEmailNotification] = React.useState<EmailNotification>({
        personFieldKey: props.value?.action.personFieldKey || "",
        emailSubject: props.value?.action.subject || "",
        emailBody: props.value?.action.body || ""
    });

    const fieldDefinitions: FieldDefinition[] = props.advancedList?.fieldDefinitions ?? [];

    React.useEffect(() => {
        let valid = true;
        if (!workflowName?.trim().length) {
            setFormFieldWorkflowName({
                errorText: "Workflow Name is required"
            });
            valid = false;
        } else {
            setFormFieldWorkflowName({
                errorText: ""
            });
        }

        if (!selectedField) {
            setFormField_beginDate({
                errorText: "Begin date column is required"
            });
            valid = false;
        } else {
            setFormField_beginDate({
                errorText: ""
            });
        }

        if (!input_intervalInDays.value) {
            setFormField_intervalInDays({
                errorText: "Interval days from begin date is required"
            });
            valid = false;
        } else {
            setFormField_intervalInDays({
                errorText: ""
            });
        }

        if (
            !(
                emailNotification?.personFieldKey?.trim().length &&
                emailNotification?.emailSubject?.trim().length &&
                emailNotification?.emailBody?.trim().length
            )
        ) {
            valid = false;
        }

        if (!valid) {
            console.log(`not a valid data`, workflowName, emailNotification);
            return;
        }

        const condition = new Condition.ItemDateFieldValueCadenceReached(selectedField.fieldKey, Number(input_intervalInDays.value));

        const action = new Action.EmailToPersonFieldValue(
            emailNotification!.emailSubject!.trim(),
            emailNotification!.emailBody!.trim(),
            emailNotification!.personFieldKey!.trim()
        );

        const workflowPayload = Workflow.Payload.init({
            id: props.value?.id,
            name: workflowName.trim(),
            conditionType: Condition.Type.ItemDateFieldValueCadenceReached,
            condition,
            action,
            actionType: Action.Type.EmailToPersonFieldValue,
            variables: variableList,
            ref: props.value?.ref,
            disabled: props.value?.disabled
        });

        props.onUpdated?.(workflowPayload);
    }, [workflowName, selectedField, input_intervalInDays.value, emailNotification]);

    return (
        <SpaceBetween size={"s"}>
            <SpaceBetween direction="vertical" size="xxs">
                <Header headingTagOverride="h4">Workflow Name</Header>
                <FormField errorText={formFieldWorkflowName.errorText}>
                    <Input value={workflowName} onChange={(e) => setWorkflowName(e.detail.value)} />
                </FormField>
            </SpaceBetween>

            <SpaceBetween direction="vertical" size="xxs">
                <Header headingTagOverride="h4">Workflow Criteria</Header>
                <UIField.CustomField
                    name="Use value of which field as begin date?"
                    description="Choose a date field"
                    errorText={formField_beginDate.errorText as string}
                    child={
                        <StaticListSelect.Single<FieldDefinition>
                            data={fieldDefinitions.filter((e) => e.dataType === FieldDefinitionDataType.date)}
                            selected={selectedField}
                            onSelectChange={(selectedFieldDefintion: FieldDefinition) => {
                                setSelectedField(selectedFieldDefintion);
                            }}
                            fromOption={(option: SelectProps.Option) => {
                                return fieldDefinitions.find(
                                    (field) => field.fieldKey === option.value!
                                )! as FieldDefinition;
                            }}
                            toOption={(fieldDefinition: FieldDefinition) => {
                                return {
                                    label: fieldDefinition?.displayName!,
                                    value: fieldDefinition?.fieldKey!
                                };
                            }}
                        />
                    }
                />
                <UIField.CustomField
                    name="Every X days since begin date?"
                    description="Input a positive or negative integer number"
                    errorText={formField_intervalInDays.errorText as string}
                    child={
                        <Input
                            {...input_intervalInDays}
                            type="number"
                            onChange={(e) => {
                                if (!e.detail.value) {
                                    setFormField_intervalInDays({
                                        errorText: "Interval in days is required"
                                    });
                                    return;
                                }
                                setInput_intervalInDays((prev) => ({
                                    ...prev,
                                    value: e.detail.value
                                }));
                            }}
                        />
                    }
                />
            </SpaceBetween>

            <EmailNotification
                fieldDefinitions={props.advancedList.fieldDefinitions ?? []}
                emailNotification={emailNotification}
                fieldConfigurations={props.fieldConfigurations}
                workflowNotificationType={Condition.Type.ItemDateFieldValueCadenceReached}
                onChanged={(value: EmailNotification) => {
                    setEmailNotification(value);
                }}
                variables={props.value?.variables || props.variables}
            />
        </SpaceBetween>
    );
};
