import { InstanceFactory } from "@amzn/ask-legal-domain";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import * as React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { PageLibraryPortal } from "./PageLibraryPortal";

export const pageLibraryRoutes = [
    {
        path: "/page-library/:pageLibraryId/admin",
        content: (props: RouteComponentProps) => {
            let { pageLibraryId } = useParams<{ pageLibraryId: string }>();
            return <PageLibraryPortal id={pageLibraryId}/>;
        },
        breadcrumbs: (props: RouteComponentProps) => {
            let { pageLibraryId } = useParams<{ pageLibraryId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Admin Portal", href: `#/instance/${InstanceFactory.fromEntityId(pageLibraryId)}/admin` },
                { text: "Page Library Admin Portal", href: `#/page-library/${pageLibraryId}/admin` }
            ]}/>;
        },
        header: () => {
            let { pageLibraryId } = useParams<{ pageLibraryId: string }>();
            const instanceId = InstanceFactory.fromEntityId(pageLibraryId);
            return <SherpaHeader headerType="Other" instanceId={instanceId}/>;
        },
        footer: () => {
            let { pageLibraryId } = useParams<{ pageLibraryId: string }>();
            const instanceId = InstanceFactory.fromEntityId(pageLibraryId);
            return <SherpaFooter variant="AdminPortal" entityId={instanceId}/>;
        }
    }
];