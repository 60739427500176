import * as React from "react";
import { ServerSideCollection } from "../../hooks/legal-contacts-collection-hook";
import {
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { LegalContact, PageFactory } from "@amzn/ask-legal-domain";
import { ErrorFlashbar } from "../common/ErrorFlashbar";

export const ServerSideLegalContactsTable = <T extends LegalContact.Record, >(props: {
    collection: ServerSideCollection<T>;
    headerActions?: React.ReactNode;
}) => {
    const getContainerURLWithFilter = (containerId: string, legalContactUserId: string) => {
        if (!containerId) return "";
        return `#/page/${
            PageFactory.fromEntityId(props.collection.tableProps.props.selectedItems[0].containerId)
        }/live?container=${
            props.collection.tableProps.props.selectedItems[0].containerId
        }&filter=contact: ${legalContactUserId}`;
    };

    const baseHeader = <Header
        variant="h2"
        counter={`(${props.collection.tableProps.props.items.length}/${props.collection.tableProps.props.totalItemsCount})`}
        actions={
            <SpaceBetween size="m" direction="horizontal">
                <Button
                    variant="icon"
                    iconName="refresh"
                    loading={props.collection.tableProps.loading}
                    onClick={props.collection.loadItems}
                />
                <Button
                    disabled={props.collection.tableProps.props.selectedItems.length < 1}
                    href={getContainerURLWithFilter(
                        props.collection.tableProps.props.selectedItems[0]?.containerId,
                        props.collection.tableProps.props.selectedItems[0]?.legalContactUser.id
                    )}
                    target="_blank"
                >
                    View on Page
                </Button>
                {props.headerActions}
            </SpaceBetween>
        }
    >Legal Contacts</Header>;

    return <SpaceBetween size="s">
        {props.collection.tableProps.error && <ErrorFlashbar error={props.collection.tableProps.error} />}
        <div className="legal-contacts-table">
            <Table
                {...props.collection.tableProps.props}
                header={baseHeader}
                empty={<>Nothing found</>}
                preferences={
                    <CollectionPreferences {...props.collection.preferenceProps.props} />
                }
                pagination={
                    <Pagination {...props.collection.paginationProps.props} />
                }
                filter={
                    <PropertyFilter {...props.collection.filteringProps.props} />
                }
            />
        </div>
    </SpaceBetween>;
};