import {
    AmazonApproval,
    AmazonApprovalTemplatePayload,
    EntityFactory,
    EntityType,
    ExternalReference,
    IEntity,
    IPageDraft,
    PageDraft
} from "@amzn/ask-legal-domain";
import {
    Alert,
    Box,
    Button,
    ButtonProps,
    Grid,
    Icon,
    Link,
    Modal,
    SpaceBetween,
    Spinner,
    TextContent
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAPI, useAPI2 } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { getApprovalBaseUrl } from "../../utils/common-utils";
import { EntitySelection } from "../common/EntitySelection";
import { UIField } from "../common/UIField";

type ApprovalActions = "resume" | "cancel" | "publish";

export const PageDraftApprovalFlowNew = (props: {
    state: UIModel.State<PageDraft.Data>;
    isEditing: boolean;
}) => {
    const context = React.useContext(AppContext);
    const history = useHistory();
    const appStage = context.getStage();
    const AABaseUrl = getApprovalBaseUrl(appStage);

    const [enableApprovalWorkflow, setEnableApprovalWorkflow] = React.useState<boolean>();
    const [submitModalVisible, setSubmitModalVisible] = React.useState<boolean>();
    const [
        selectedApprovalTemplate,
        setSelectedApprovalTemplate
    ] = React.useState<ExternalReference.Data<AmazonApprovalTemplatePayload>>();
    const [approval, setApproval] = React.useState<ExternalReference.Data<AmazonApproval>>(null);

    const loadPageLibraryRunner = useAPI(
        context.getPageLibraryAPI().load
    );
    const loadApprovalRunner = useAPI2<IEntity.LoadEntityInput, ExternalReference.Data<AmazonApproval>>(
        context.getCommonAPI().load
    );
    const approvalSubmitRunner = useAPI2(
        context.getPageDraftAPI().submit2
    );
    const publishDraftRunner = useAPI(
        context.getPageDraftAPI().publish
    );
    const refreshApprovalStatusRunner = useAPI2(
        context.getPageDraftAPI().refresh
    );
    const approvalWorkFlowRunner = useAPI2(
        async (action: ApprovalActions) => {
            const api = context.getPageDraftAPI();
            switch (action) {
                case "resume":
                    return await api.resume(props.state.value.id);
                case "cancel":
                    return await api.cancelReview(props.state.value.id);
                default:
                    return;
            }
        }
    );

    const refreshApprovalStatus = () => {
        refreshApprovalStatusRunner.invoke(props.state.value.id);
    };

    const onClick = (action: ApprovalActions) => {
        if (action === "publish") {
            publishDraftRunner.submitRun(props.state.value.id);
        }
        if (action === "resume") {
            approvalWorkFlowRunner.invoke("resume");
        }
        if (action === "cancel") {
            approvalWorkFlowRunner.invoke("cancel");
        }
    };

    const getApprovalFullUrl = (approvalId: string) => {
        return `${AABaseUrl}Approval/Details/${approvalId}`;
    };

    const onSubmitForApproval = () => {
        const apiInput = Builder<IPageDraft.SubmitForApprovalInput>()
            .draftId(props.state.value.id)
            .template(EntityFactory.toEntityRef(selectedApprovalTemplate))
            .build();
        approvalSubmitRunner.invoke(
            apiInput
        );
    };

    const onDismissApprovalModal = () => {
        setSubmitModalVisible(false);
        setSelectedApprovalTemplate(undefined);
    };

    const loadApproval = (approvalId: string) => {
        loadApprovalRunner.invoke(
            IEntity.LoadEntityInput.create(
                approvalId,
                EntityType.ExternalReference
            )
        );
    };

    React.useEffect(() => {
        loadPageLibraryRunner.submitRun(
            props.state.value.pageLibraryId
        );
        if (!!props.state.value.approval) {
            // Load approval initially if approval is there
            loadApproval(props.state.value.approval.id);
        }
    }, []);

    React.useEffect(() => {
        if (loadPageLibraryRunner.status === "Succeeded") {
            setEnableApprovalWorkflow(loadPageLibraryRunner.data.output.enableApprovalWorkflow);
        }
    }, [loadPageLibraryRunner.status]);

    React.useEffect(() => {
        if (approvalSubmitRunner.status === "Succeeded") {
            props.state.setValue(approvalSubmitRunner.output);
            setSubmitModalVisible(false);
            setSelectedApprovalTemplate(undefined);
            if (!!approvalSubmitRunner.output.approval) {
                // Load approval when creation is successful
                loadApproval(approvalSubmitRunner.output.approval.id);
            }
        } else if (approvalSubmitRunner.status === "Error") {
            setSelectedApprovalTemplate(undefined);
            // TODO: Alert user with appropriate Error message
        }
    }, [approvalSubmitRunner.status]);

    React.useEffect(() => {
        if (loadApprovalRunner.status === "Succeeded") {
            setApproval(loadApprovalRunner.output);
        }
    }, [loadApprovalRunner.status]);

    React.useEffect(() => {
        if (approvalWorkFlowRunner.status === "Succeeded") {
            if (
                (!!approvalWorkFlowRunner.output.approval && !props.state.value.approval) ||
                (!!approvalWorkFlowRunner.output.approval && !!props.state.value.approval && approvalWorkFlowRunner.output.approval.id !== props.state.value.approval.id) ||
                (!!approvalWorkFlowRunner.output.approval && !!props.state.value.approval && approvalWorkFlowRunner.output.approval.version !== props.state.value.approval.version)
            ) {
                loadApprovalRunner.invoke(
                    IEntity.LoadEntityInput.create(
                        approvalWorkFlowRunner.output.approval!.id,
                        EntityType.ExternalReference
                    )
                );
            }
            props.state.setValue(approvalWorkFlowRunner.output);
        }
    }, [approvalWorkFlowRunner.status]);


    React.useEffect(() => {
        if (
            approvalSubmitRunner.status === "Succeeded" &&
            loadApprovalRunner.status === "Succeeded" &&
            approvalSubmitRunner.output.approval?.id === loadApprovalRunner.output.id
        ) {
            const win = window.open(getApprovalFullUrl(loadApprovalRunner.output.payload.approvalId), "_blank");
            win.focus();
        }
    }, [approvalSubmitRunner.status, loadApprovalRunner.status]);

    React.useEffect(() => {
        if (publishDraftRunner.status === "Succeeded") {
            history.push(`/page/${publishDraftRunner.data.output.newPage.id}/live`);
        }
    }, [publishDraftRunner.status]);

    React.useEffect(() => {
        if (refreshApprovalStatusRunner.status === "Succeeded") {
            props.state.setValue(refreshApprovalStatusRunner.output.refreshedDraft);
            setApproval(refreshApprovalStatusRunner.output.refreshedApproval);
        }
    }, [refreshApprovalStatusRunner.status]);

    const getDraftButtons = () => {
        const buttons: ButtonProps[] = [];
        let showRefreshButton = false;
        if (props.state.value.status === "PUBLISHED") {
            return;
        }
        if (!enableApprovalWorkflow) {
            buttons.push(
                Builder<ButtonProps>()
                    .children("Publish")
                    .onClick(e => onClick("publish"))
                    .variant("primary")
                    .disabled(props.isEditing)
                    .build()
            );
        } else {
            if (AABaseUrl && !!props.state.value.approval) {
                if (loadApprovalRunner.status === "Running") {
                    buttons.push(
                        Builder<ButtonProps>()
                            .children("View Approval")
                            .target("_blank")
                            .variant("primary")
                            .loading(true)
                            .disabled(props.isEditing)
                            .build()
                    );
                } else if (!!approval) {
                    if (approval.payload.approvalStatus === "INVALIDATED") {
                        buttons.push(
                            Builder<ButtonProps>()
                                .children("View Invalidated Approval")
                                .href(getApprovalFullUrl(approval.payload.approvalId))
                                .target("_blank")
                                .variant("primary")
                                .disabled(props.isEditing)
                                .build()
                        );
                    } else if (approval.payload.approvalStatus === "REJECTED") {
                        buttons.push(
                            Builder<ButtonProps>()
                                .children("View Rejected Approval")
                                .href(getApprovalFullUrl(approval.payload.approvalId))
                                .target("_blank")
                                .variant("primary")
                                .disabled(props.isEditing)
                                .build()
                        );
                    } else if (approval.payload.approvalStatus === "DRAFT") {
                        buttons.push(
                            Builder<ButtonProps>()
                                .children("Start Approval")
                                .href(getApprovalFullUrl(approval.payload.approvalId))
                                .target("_blank")
                                .variant("primary")
                                .disabled(props.isEditing)
                                .build()
                        );
                    } else {
                        buttons.push(
                            Builder<ButtonProps>()
                                .children("View Approval")
                                .href(getApprovalFullUrl(approval.payload.approvalId))
                                .target("_blank")
                                .variant("primary")
                                .disabled(props.isEditing)
                                .build()
                        );
                    }
                }
            }
            showRefreshButton = true;
            if (props.state.value.status === "DRAFT") {
                buttons.push(
                    Builder<ButtonProps>()
                        .children("Submit for Approval")
                        .onClick(e => { setSubmitModalVisible(true); })
                        .variant("primary")
                        .disabled(props.isEditing)
                        .build()
                );
            } else if (props.state.value.status === "PENDING_APPROVAL") {
                buttons.push(
                    Builder<ButtonProps>()
                        .children("Cancel Approval")
                        .onClick(e => onClick("cancel"))
                        .variant("normal")
                        .disabled(props.isEditing)
                        .build()
                );
            } else if (props.state.value.status === "APPROVED") {
                buttons.push(
                    Builder<ButtonProps>()
                        .children("Publish")
                        .onClick(e => onClick("publish"))
                        .variant("primary")
                        .disabled(props.isEditing)
                        .build()
                );
            } else if (props.state.value.status === "REJECTED") {
                buttons.push(
                    Builder<ButtonProps>()
                        .children("Resume Editing")
                        .onClick(e => onClick("resume"))
                        .variant("primary")
                        .disabled(props.isEditing)
                        .build()
                );
            }
        }

        return <SpaceBetween direction="horizontal" size="s">
            {showRefreshButton && <Box margin={{top: "xxs"}}>
                {refreshApprovalStatusRunner.status === "Running" ? (
                    <Spinner/>
                ) : (
                    <div onClick={refreshApprovalStatus}>
                        <Icon name="refresh" />
                    </div>
                )}
            </Box>}
            {buttons.map((b) =>
                <Button {...b}
                    loading={
                        publishDraftRunner.status === "Running" ||
                        approvalSubmitRunner.status === "Running" ||
                        refreshApprovalStatusRunner.status === "Running" ||
                        approvalWorkFlowRunner.status === "Running"
                    }
                />
            )}
        </SpaceBetween>;
    };

    const getAlertContent = () => {
        const pathfinderDraftStatus = props.state.value.status;
        if (pathfinderDraftStatus === "PUBLISHED") {
            return "Draft is published, click to create new draft based on current live page";
        }
        if (!enableApprovalWorkflow) {
            return "No approval required for this draft, you can directly publish to live";
        }
        else {
            if (pathfinderDraftStatus === "DRAFT") {
                if (!!approval) {
                    const amazonApprovalStatus = approval.payload.approvalStatus;
                    return `There is an existing Approval request in [${amazonApprovalStatus}] status, click view button to check more details or submit another approval request`;
                }
                return "Please submit for approval";
            }
            if (pathfinderDraftStatus === "PENDING_APPROVAL") {
                if (
                    !!approval &&
                    approval.payload.approvalStatus === "DRAFT"
                ) {
                    return "Please finish submitting your approval through the Approvals tool";
                }
                return `Approval is pending, please wait until all approvers approve your request...`;
            }
            if (pathfinderDraftStatus === "APPROVED") {
                if (
                    !!approval &&
                    approval.payload.approvalStatus === "APPROVED_WITH_CONDITION"
                ) {
                    return "Request is approved with conditions, please View Approval for more details or publish directly if the conditions are met";
                }
                return `Request is approved, please publish the draft`;
            }
            if (pathfinderDraftStatus === "REJECTED") {
                return `Approval is rejected, please resume editing and re-submit for approval`;
            }
        }
    };

    const getAlertType = () => {
        if (!enableApprovalWorkflow) {
            return "success";
        } else if (props.state.value.status === "REJECTED") {
            return "error";
        } else if (props.state.value.status === "APPROVED" || props.state.value.status === "PUBLISHED") {
            return "success";
        } else return "info";
    };

    return (
        <React.Fragment>
            <div>
                <Alert
                    dismissible={false}
                    type={
                        loadPageLibraryRunner.status === "Error" ? "error" : getAlertType()
                }>
                    {loadPageLibraryRunner.status === "Running" && <Spinner/>}
                    {loadPageLibraryRunner.status === "Error" &&
                        <TextContent>An unexpected error occured, please
                            <span onClick={() => loadPageLibraryRunner.reload()}>
                                <Link>Retry</Link>
                            </span>
                        </TextContent>}
                    {loadPageLibraryRunner.status === "Succeeded" && <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <TextContent>
                            <p>{getAlertContent()}</p>
                        </TextContent>
                        <Box float={"right"}>
                            {getDraftButtons()}
                        </Box>
                    </Grid>}
                </Alert>
            </div>
            {submitModalVisible && <Modal
                visible
                header="Submit for approval"
                footer={<Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={onDismissApprovalModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={!selectedApprovalTemplate}
                            loading={approvalSubmitRunner.status === "Running"}
                            onClick={onSubmitForApproval}>
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box>}
                onDismiss={onDismissApprovalModal}
            >
                <UIField.LabelField
                    label="Approval Template"
                >
                    <EntitySelection.Single
                        api={context.getExternalReferenceAPI().loadByBaseEntity}
                        input={EntityFactory.fromEntityAttributes(props.state.value.pageLibraryId, EntityType.PageLibrary)}
                        selected={selectedApprovalTemplate}
                        onSelectionChange={(template) => {
                            setSelectedApprovalTemplate(template as ExternalReference.Data<AmazonApprovalTemplatePayload>);
                        }}
                    />
                </UIField.LabelField>
            </Modal>}
        </React.Fragment>
    );
};
