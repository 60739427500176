import {
    Container,
    ContainerRef,
    IContainer,
    Page
} from "@amzn/ask-legal-domain";
import {
    Alert,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { PageDraftModel } from "../../model/page-draft-model";
import { ContainerEdit } from "../container/ContainerEdit";
import { ContainerView } from "../container/ContainerView";
import {
    StringUtils,
    getQueryParameterMap,
    isCompareFeatureActivated
} from "../../utils/common-utils";
import { Element } from "react-scroll";
import "../../styles/util-styles.scss";
import { UIModel } from "../../model/ui-model";
import { PageOverviewSection } from "./PageOverviewSection";
import { AppContext } from "../../setup/context";
import { Preference } from "../../setup/preference";

export namespace PageSection {
    export const Edit = (props: {
        location: Page.SectionLocation;
        updateOrderState: PageDraftModel.ReorderContainerState;
        addContainerState: PageDraftModel.EditContainerState;
        activeEditContainerState: UIModel.State<string>;
        pageBottomRef?: any;
        liveContainerMap?: Map<string, ContainerRef>;
    }) => {
        const context = React.useContext(AppContext);
        const [containerNames, setContainerNames] = React.useState<{ id: string, name: string }[]>([]);
        const onLoadComplete = (loadedData: IContainer.LoadContainerOutput) => {
            const containerId = loadedData.containerMetadata.id;
            const containerName = loadedData.containerMetadata.title;
            setContainerNames((prev) => [...prev, { id: containerId, name: containerName }]);
        };
        const [showContainerNotFoundAlert, setShowContainerNotFoundAlert] = React.useState<boolean>(false);

        // This effect hook is to check whether scroll-to container has been deleted already
        React.useEffect(() => {
            const queryParamMap = getQueryParameterMap();
            if (queryParamMap.has("container")) {
                const containerId = queryParamMap.get("container");
                if (!props.updateOrderState.draftField.value.newSections
                        .flatMap(s => s.containers)
                        .find(c => c.id === containerId)) {
                    setShowContainerNotFoundAlert(true);
                }
            }
        }, []);

        const getButtonDropDown = () => {
            let items: ButtonDropdownProps.Items = [];
            if (props.location === "Main") {
                items = [
                    SectionButtonDropdown.AddRichText,
                    SectionButtonDropdown.AddAccordion,
                    SectionButtonDropdown.AddAccordionWithGroup,
                    SectionButtonDropdown.AddCustomList,
                    SectionButtonDropdown.AddDocument,
                    SectionButtonDropdown.AddDecisionTree,
                    SectionButtonDropdown.AddWhosMyLawyer
                ];
                if (context.hasLab(Preference.Lab.AdvancedList)) {
                    items = [
                        ...items,
                        SectionButtonDropdown.AdvancedList
                    ];
                }
            } else if (props.location === "Right") {
                items = [
                    SectionButtonDropdown.AddCustomList,
                ];
            } else {
                items = [];
            }
            return (
                <ButtonDropdown
                    variant="primary"
                    disabled={!StringUtils.isEmpty(props.addContainerState.activeContainerTypeField.value)}
                    items={items}
                    onItemClick={(event: { detail: { id: string } }) => {
                        buttonDropdownEventHandler(event.detail.id);
                    }}>
                    Add {props.location} Container
                </ButtonDropdown>
            );
        };

        const buttonDropdownEventHandler = (buttonId: string) => {
            props.addContainerState.locationField.setValue(props.location);
            switch (buttonId) {
                case SectionButtonDropdown.AddRichText.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.RICH_TEXT);
                    break;
                case SectionButtonDropdown.AddAccordion.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.ACCORDION);
                    break;
                case SectionButtonDropdown.AddAccordionWithGroup.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.ACCORDION_GROUP);
                    break;
                case SectionButtonDropdown.AddCustomList.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.LIST);
                    break;
                case SectionButtonDropdown.AddDocument.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.DOCUMENT);
                    break;
                case SectionButtonDropdown.AddDecisionTree.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.DECISION_TREE);
                    break;
                case SectionButtonDropdown.AddWhosMyLawyer.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.WHOS_MY_LAWYER);
                    break;
                case SectionButtonDropdown.AdvancedList.id:
                    props.addContainerState.activeContainerTypeField.setValue(Container.Type.ADVANCED_LIST);
                    break;
            }
        };

        return (
            <div>
                <SpaceBetween size="xxl">
                    {props.location === "Main" && showContainerNotFoundAlert &&
                        <Alert
                            dismissible
                            type="error"
                            header="Container Not Found"
                            onDismiss={() => setShowContainerNotFoundAlert(false)}>
                            The container you are trying to edit has been removed
                        </Alert>
                    }
                    {getButtonDropDown()}
                    {
                        props.location === "Main" &&
                        props.updateOrderState
                            .newOrderedSectionsField
                            .value.find(s => s.location === "Main")
                            .containers.length > 0 &&
                        <PageOverviewSection
                            state={props.updateOrderState
                                .newOrderedSectionsField
                                .value.find(s => s.location === "Main")
                                .containers}
                            containerNames={containerNames}
                        />
                    }
                    {props.location === "Main" && props.updateOrderState.newOrderedSectionsField.value
                        .find(s => s.location === props.location)
                        .containers
                        .map(c =>
                            <div>
                                <a id={c.id} />
                                <Element name={c.id} />
                                <ContainerEdit.Preload
                                    id={c.id}
                                    updateOrderState={props.updateOrderState}
                                    onLoadComplete={onLoadComplete}
                                    liveContainerRef={props.liveContainerMap.get(c.id)}
                                    activeEditContainerId={props.activeEditContainerState}
                                />
                            </div>
                        )
                    }
                    {props.location === "Right" && props.updateOrderState.newOrderedSectionsField.value
                        .find(s => s.location === props.location)
                        .containers
                        .map(c =>
                            <ContainerEdit.Preload
                                activeEditContainerId={props.activeEditContainerState}
                                id={c.id}
                                updateOrderState={props.updateOrderState}
                                liveContainerRef={props.liveContainerMap.get(c.id)}
                            />
                        )
                    }
                </SpaceBetween>
                {props.location === "Main" &&
                    <div ref={props.pageBottomRef} />
                }
            </div>
        );
    };

    export const View = (props: {
        section: Page.Section;
        draftView?: boolean;
        liveContainerMap?: Map<string, ContainerRef>;
        editor?: boolean;
    }) => {
        const [pageControlState, dispatch] = React.useReducer(
            pageControlReducer,
            props.section.containers.map(c => true)
        );
        const [expandCollapseAllTrigger, setExpandCollapseAllTrigger] = React.useState<{ value: string}>();
        const [containerNames, setContainerNames] = React.useState<{ id: string, name: string }[]>([]);
        const onLoadComplete = (loadedData: IContainer.LoadContainerOutput) => {
            const containerId = loadedData.containerMetadata.id;
            const containerName = loadedData.containerMetadata.title;
            setContainerNames((prev) => [...prev, { id: containerId, name: containerName }]);
        };

        const getContainersDiffMap = () => {
            if (props.section.location === "Right" || !props.liveContainerMap) return;
            const containersWithDiff = new Map<string, string>();
            const liveContainerIds = Array.from(props.liveContainerMap.keys());
            props.section.containers.forEach(c => {
                if (liveContainerIds.includes(c.id)) {
                    // Modified containers
                    const liveRef = props.liveContainerMap.get(c.id);
                    if (
                        !liveRef ||
                        (c.version !== liveRef.version &&
                            isCompareFeatureActivated(c.containerType))
                    ) containersWithDiff.set(c.id, "UPDATED");
                } else {
                    // New containers
                    if (isCompareFeatureActivated(c.containerType)) containersWithDiff.set(c.id, "INSERTED");
                }
            });
            liveContainerIds.forEach((key) => {
                if (!props.section.containers.map(x => x.id).includes(key)) containersWithDiff.set(key, "DELETED");
            });
            return containersWithDiff;
        };

        return (
            <div>
                <SpaceBetween size="xxl">
                    {
                        props.section.location === "Main" &&
                        props.section.containers.length > 0 &&
                        <div>
                            {pageControlState.every(v => v === true) ? (
                                <Button
                                    iconName="treeview-collapse" variant="link"
                                    onClick={() => {
                                        dispatch({ type: "collapse-all" });
                                        setExpandCollapseAllTrigger({ value: "collapse" });
                                    }}
                                >Collapse All</Button>
                            ) : (
                                <Button
                                    iconName="treeview-expand" variant="link"
                                    onClick={() => {
                                        dispatch({ type: "expand-all" });
                                        setExpandCollapseAllTrigger({ value: "expand" });
                                    }}
                                >Expand All</Button>
                            )}
                            <PageOverviewSection
                                state={props.section.containers}
                                    containerNames={containerNames}
                                containersDiffMap={getContainersDiffMap()}
                            />
                        </div>
                    }
                    {props.section.containers
                        .map((c, index) =>
                            <div>
                                <Element name={c.id} />
                                <ContainerView
                                    containerRef={c}
                                    onLoadComplete={onLoadComplete}
                                    location={props.section.location}
                                    isExpanded={pageControlState[index]}
                                    containerIndex={index}
                                    pageControlDispatch={dispatch}
                                    showFavoriteButton={props.section.location === "Main"}
                                    showCompare={!!props.draftView}
                                    showEditButton={!!props.editor}
                                    liveContainerRef={props.liveContainerMap?.get(c.id)}
                                    expandCollapseAllTrigger={expandCollapseAllTrigger}
                                />
                            </div>
                        )
                    }
                </SpaceBetween>
            </div>
        );
    };

    export namespace SectionButtonDropdown {
        export const AddRichText = Builder<ButtonDropdownProps.Item>()
            .id("addRichText")
            .text("Add Rich Text Container")
            .build();
        export const AddCustomList = Builder<ButtonDropdownProps.Item>()
            .id("addCustonList")
            .text("Add Custom List Container")
            .build();
        export const AddAccordion = Builder<ButtonDropdownProps.Item>()
            .id("addAccordion")
            .text("Add Accordion Container")
            .build();
        export const AddAccordionWithGroup = Builder<ButtonDropdownProps.Item>()
            .id("addAccordionWithGroup")
            .text("Add Accordion With Group Container")
            .build();
        export const AddDocument = Builder<ButtonDropdownProps.Item>()
            .id("addDocument")
            .text("Add Document Container")
            .build();
        export const AddDecisionTree = Builder<ButtonDropdownProps.Item>()
            .id("addDecisionTree")
            .text("Add Decision Tree Container")
            .build();
        export const AddWhosMyLawyer = Builder<ButtonDropdownProps.Item>()
            .id("addWhosMyLawyer")
            .text("Add Who's My Legal Contact Container")
            .build();
        export const AdvancedList = Builder<ButtonDropdownProps.Item>()
            .id("addAdvancedList")
            .text("Add Advanced List Container")
            .build();
    }

    // to control containers expand status
    function pageControlReducer(
        state: boolean[], // state[i] => expand status for container at index i
        params: {
            type: "expand-all" | "collapse-all" | "toggle",
            index?: number
        }
    ): boolean[] {
        switch (params.type) {
            case "expand-all":
                return state.map(s => true);
            case "collapse-all":
                return state.map(s => false);
            case "toggle":
                state[params.index] = !state[params.index];
                return [...state];
            default:
                throw new Error(`Unexpected type ${params.type}`);
        }
    }
}