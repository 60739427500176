import {
    IdentityRef,
    EntityExtraVersionRef,
    GenInstanceSandboxFilePutUrlCommandInput,
    GenInstanceSandboxFilePutUrlCommandOutput,
    SandboxFileKeyFileName
} from "@amzn/altar-sds-client";
import axios, { AxiosRequestConfig } from "axios";
import { APIOutput } from "@amzn/ask-legal-domain";
import {
    FileUpload,
    Flashbar,
    FlashbarProps,
    FormField,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { Builder } from "builder-pattern";

export function AttachmentUpload(props: {
    itemRef: EntityExtraVersionRef;
    by: IdentityRef;
    setUploadingSandboxFiles: (val: boolean) => void;
    onUpdated?: (sandboxFiles: SandboxFileKeyFileName[]) => void;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [files, setFiles] = React.useState<File[]>([]);

    async function uploadAttachments() {
        props.setUploadingSandboxFiles(true);
        setSpinnerProps({});
        let sandboxFiles: SandboxFileKeyFileName[] = [];
        for await (const file of files) {
            const putSignedUrlOutput = await context
            .getAdvancedListAPI()
            .generateSandboxPutUrl(Builder<GenInstanceSandboxFilePutUrlCommandInput>()
                .by(props.by)
                .contentType(file.type)
                .fileName(file.name)
                .instanceId(props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId!)
                .build()
            );
            const output = APIOutput.fromRaw<GenInstanceSandboxFilePutUrlCommandOutput>(putSignedUrlOutput.data);
            if (output.isErr()) {
                setSpinnerProps(undefined);
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
                props.setUploadingSandboxFiles(false);
            } else {
                const req: AxiosRequestConfig = {
                    url: output.data.url,
                    method: "PUT",
                    data: file,
                };
                await axios(req);
                sandboxFiles.push({
                    fileKey: output.data.fileKey,
                    fileName: file.name
                });
            }
        }
        setSpinnerProps(undefined);
        setFlashbarProps({
            items: []
        });
        props.onUpdated(sandboxFiles);
        props.setUploadingSandboxFiles(false);
    }

    React.useEffect(() => {
        if (files?.length) {
            uploadAttachments();
        }
    }, [files]);

    return (
        <>
            {spinnerProps && (
                <Spinner />
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            <FormField
                label={<h5>Upload Attachments</h5>}
                description="You can also drop files here to upload"
            >
                <FileUpload
                    onChange={({ detail }) => {
                        setFiles(detail.value);
                    }}
                    multiple
                    value={files}
                    i18nStrings={{
                        uploadButtonText: e =>
                        e ? "Choose files" : "Choose file",
                        dropzoneText: e =>
                        e
                            ? "Drop files to upload"
                            : "Drop file to upload",
                        removeFileAriaLabel: e =>
                        `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error"
                    }}
                    showFileSize
                    tokenLimit={5}
                />
            </FormField>
        </>
    );
}
