import * as React from "react";
import { RouteComponentProps, useLocation, useParams } from "react-router-dom";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { SearchHome } from "./SearchHome";

export const searchRoutes = [
    {
        path: "/search/:instanceId",
        content: (props: RouteComponentProps) => {
            let { instanceId } = useParams<{ instanceId: string }>();
            const params = new URLSearchParams(useLocation().search);
            let queryText = "";
            if (params.has("queryText")) {
                queryText = params.get("queryText");
            }
            return <SearchHome instanceId={instanceId} queryText={queryText}/>;
        },
        header: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaHeader headerType="Other" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useParams<{ instanceId: string }>();
            return <SherpaFooter variant="LivePage" entityId={instanceId}/>;
        }
    }
];