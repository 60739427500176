import * as React from "react";
import { useServerSideCollection } from "../../hooks/legal-contacts-collection-hook";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { LegalContactsPolarisFactory } from "../../factory/polaris/legal-contacts-polaris-factory";
import { ServerSideLegalContactsTable } from "./ServerSideLegalContactsTable";

export const AllLegalContactsTable = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const loadAllLegalContactsApiRunner = useAPI2(
        context.getLegalContactAPI().loadAll
    );
    const collection = useServerSideCollection(
        loadAllLegalContactsApiRunner,
        props.instanceId,
        {
            columnDefinitions: LegalContactsPolarisFactory.Table.toColumnDefinition(),
            defaultSortingColumn: LegalContactsPolarisFactory.DEFAULT_SORTING_STATE.sortingColumn,
            filteringProperties: LegalContactsPolarisFactory.Filters.PropertyFilters.toDefaultFilteringProperties(),
            selectionType: "single"
        }
    );
    return <ServerSideLegalContactsTable collection={collection} />;
};