import * as React from "react";
import * as Polaris from "@amzn/awsui-components-react";
import {
    APIOutput,
    AppError,
    EntityRef,
    ErrorCode,
    Filter,
    FilterField,
    GeneralEntity,
    ServerSidePaginatedLoadingInput,
    ServerSidePaginatedLoadingOutput,
    SortingField
} from "@amzn/ask-legal-domain";
import { CommonPolarisFactory } from "../factory/polaris/common-polaris-factory";
import { i18n } from "../i18n/propertyFilteringI18nString";
import { APIResponse } from "../api/common";
import { useComponnentProps } from "./polaris-component-hooks";
import { UIConstants } from "../utils/common-utils";

export function useLoadingWithServerSidePagination<T extends GeneralEntity>(
    api: (input: ServerSidePaginatedLoadingInput) => Promise<APIResponse<ServerSidePaginatedLoadingOutput<T>>>,
    partitionKey: string,
    options: {
        columnDefinitions: Polaris.TableProps.ColumnDefinition<any>[],
        defaultTablePreferences: Polaris.CollectionPreferencesProps.Preferences<any>;
        pageSizeSelectionOptions: Polaris.CollectionPreferencesProps.PageSizeOption[];
        selectionType?: "multi" | "single"
    },
    filter?: {
        filteringProperties: Polaris.PropertyFilterProps.FilteringProperty[];
        toOption?: (items: T[]) => { propertyKey: string, value: string }[];
        initialFilter?: Polaris.PropertyFilterProps.Token[];
    },
    entityRef?: EntityRef,
) {
    const table = useComponnentProps<Polaris.TableProps<T>>({
        columnDefinitions: options.columnDefinitions,
        sortingDescending: false,
        items: [],
        selectedItems: [],
        selectionType: options.selectionType,
        onSelectionChange: e => table.setProps((prev) => ({
            ...prev,
            selectedItems: e.detail.selectedItems
        })),
        sortingColumn: null,
        onSortingChange: e => table.setProps((prev) => ({
            ...prev,
            sortingColumn: { sortingField: e.detail.sortingColumn.sortingField },
            sortingDescending: e.detail.isDescending
        })),
        loadingText: "Loading...",
        loading: false,
        visibleColumns: options.defaultTablePreferences.visibleContent
    });

    const tablePagination = useComponnentProps<Polaris.PaginationProps>({
        pagesCount: 0,
        currentPageIndex: 1, // page index starts from 1
        onChange: (e) => {
            tablePagination.setProps((prev) => ({
                ...prev,
                currentPageIndex: e.detail.currentPageIndex
            }));
        }
    });

    const tablePreference = useComponnentProps<Polaris.CollectionPreferencesProps>({
        cancelLabel: "Cancel",
        confirmLabel: "Confirm",
        title: "Table Preferences",
        preferences: options.defaultTablePreferences,
        pageSizePreference: {
            title: "Select page size",
            options: options.pageSizeSelectionOptions
        },
        visibleContentPreference: CommonPolarisFactory.Table.VisibleContentPreference.toVisibleContentPreference(
            options.columnDefinitions
        ),
        onConfirm: (e) => {
            tablePreference.setProps((prev) => ({
                ...prev,
                preferences: e.detail
            }));
        }
    });

    const tableFilteringQuery = useComponnentProps<Polaris.PropertyFilterProps.Query>({
        tokens: (filter && filter.initialFilter) ? filter.initialFilter : [],
        operation: "or"
    });

    const tableFiltering = useComponnentProps<Polaris.PropertyFilterProps>({
        query: tableFilteringQuery.props,
        onChange: e => tableFilteringQuery.setProps(prev => ({
            ...prev,
            operation: e.detail.operation,
            tokens: e.detail.tokens.map(t => ({
                ...t,
                propertyKey: t.propertyKey ? t.propertyKey : FilterField.Any
            }))
        })),
        filteringOptions: (!!filter && filter.toOption) ? filter.toOption([...table.props.items]) : [],
        i18nStrings: i18n,
        filteringProperties: !!filter ? filter.filteringProperties : [],
        hideOperations: true,
    });

    const getFilters = (): Filter[] => {
        // Configure this function to apply different kind of filters
        const filters: Filter[] = tableFiltering.props.query.tokens.map((entry) => {
            return {
                value: entry.value,
                field: entry.propertyKey ? entry.propertyKey as FilterField : FilterField.Any,
                negate: entry.operator === "!=" ? true : false,
                operator: entry.propertyKey === FilterField.Name ? "must" : "optional"
            };
        });

        return filters;
    };

    const loadItems = async () => {
        try {
            table.setProps((prev) => ({
                ...prev,
                loading: true
            }));
            const filters = getFilters();
            let input = ServerSidePaginatedLoadingInput.create({
                partitionKey: partitionKey,
                currentPageIndex: tablePagination.props.currentPageIndex,
                pageSize: tablePreference.props.preferences.pageSize,
                filters: filters, // configure this array when we need to table filtering
                sorting: table.props.sortingColumn ? {
                    field: table.props.sortingColumn.sortingField as SortingField,
                    order: table.props.sortingDescending ? "DESC" : "ASC"
                } : null
            });
            const rawOutput = await api(input);

            // User changed pagination setting while api is loading, ignore this loading
            if (input.pagination.currentPageIndex !== tablePagination.props.currentPageIndex ||
                input.pagination.pageSize !== tablePreference.props.preferences.pageSize
            ) {
                return;
            }
            const output = APIOutput.fromRaw<ServerSidePaginatedLoadingOutput<T>>(rawOutput.data);
            if (output.isErr()) {
                table.setError(output.err);
            } else {
                table.setProps(prev => ({
                    ...prev,
                    items: output.data.result
                }));
                tablePagination.setProps(prev => ({
                    ...prev,
                    pagesCount: Math.floor(
                        (output.data.totalCount + tablePreference.props.preferences.pageSize - 1) / tablePreference.props.preferences.pageSize
                    )
                }));
            }
        } catch (e) {
            let code = 500;
            let message = UIConstants.ERROR_MESSAGE;
            if (!!e.response && !!e.response.data && !!e.response.data.message) {
                message = e.response.data.message;
            }
            if (!!e.response && !!e.response.status && e.response.status > 0) {
                code = e.response.status;
            }
            table.setError(AppError.create(message, code as ErrorCode));
        } finally {
            table.setProps((prev) => ({
                ...prev,
                loading: false,
                selectedItems: []
            }));
        }
    };

    React.useEffect(() => {
        table.setProps(prev => ({
            ...prev,
            visibleColumns: tablePreference.props.preferences.visibleContent
        }));
    }, [tablePreference.props.preferences.visibleContent]);

    React.useEffect(() => {
        tableFiltering.setProps(prev => ({
            ...prev,
            query: tableFilteringQuery.props
        }));
    }, [tableFilteringQuery.props]);

    React.useEffect(() => {
        loadItems();
    }, [
        partitionKey,
        entityRef,
        tableFiltering.props.query,
        tablePagination.props.currentPageIndex,
        table.props.sortingColumn,
        table.props.sortingDescending
    ]);

    // When page size changes, change current page index back to 1
    React.useEffect(() => {
        if (tablePagination.props.currentPageIndex !== 1) {
            tablePagination.setProps(prev => ({
                ...prev,
                currentPageIndex: 1
            }));
        } else {
            loadItems();
        }
    }, [tablePreference.props.preferences.pageSize]);

    return {
        table,
        tablePreference,
        tablePagination,
        tableFiltering,
        loadItems
    };
}
