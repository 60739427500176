import { Spinner } from "@amzn/awsui-components-react-v3";
import { LegalAiCompanionProps } from "@amzn/legal-ai-companion-types";
import { SkillType } from "@amzn/legal-ai-companion-types";
import { loadComponent } from "@amzn/legal-client-utilities";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as React from "react";
import {
  EntityPermissionOperationNames,
  EntityType,
  IEntityPermission,
} from "@amzn/ask-legal-domain";
import { AppContext } from "../../setup/context";
import { Builder } from "builder-pattern";

const LegalAiComponent = lazy(() =>
  loadComponent("LegalGenAIUIAssets", "./ai-companion")
) as unknown as React.ComponentType<LegalAiCompanionProps>;

const AI_COMPANION_DEFAULT_PAGE_ID: string = "home";

function LegalAiCompanionComponent(props: {
  instanceId: string;
}) {
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const context = React.useContext(AppContext);
  const stageConfig = context.config;
  if (!stageConfig.legalAICompanion) return <></>;
  const documentGroupId = getDocumentGroupId(
    props.instanceId,
    stageConfig.legalAICompanion.instanceDocumentGroupIdMapping
  );

  const initRemotes = (url: string): void => {
    window["remotes"] = {
      LegalGenAIUIAssets: url,
    };
  };

  const legalAiCompanionProps: LegalAiCompanionProps = {
    stage: stageConfig.legalAICompanion.stage,
    currentPageId: AI_COMPANION_DEFAULT_PAGE_ID,
    configuration: {
      name: "Pathfinder",
      description: "",
      commonSkillsForPage: [SkillType.KnowledgeBase],
      defaultSkillType: SkillType.KnowledgeBase,
      skillConfigurations: {
        [SkillType.KnowledgeBase]: {
          title: "Pathfinder knowledge base",
          description: "I'll answer questions from pathfinder knowledge base",
          sampleQuestions: [],
          type: SkillType.KnowledgeBase,
          documentGroupId: documentGroupId,
          informationPopoverHeader: "Pathfinder knowledge base",
          informationPopoverContent:
            "Answer questions using pathfinder knowledge base",
        },
      },
    },
  };

  const isUserAdmin = async () => {
    try {
      const isAdminOutput = await context
        .getEntityPermissionAPI()
        .isAuthorized(
          Builder<IEntityPermission.IsAuthorizedInput>()
            .id(props.instanceId)
            .type(EntityType.Instance)
            .permission(EntityPermissionOperationNames.CAN_ADMIN)
            .build()
        );
      setIsAdmin((isAdminOutput.data as unknown as any).data);
    } catch (e) {
      console.error(`Error checking isAdmin`, e);
    }
  };

  React.useEffect(() => {
    if (props.instanceId) {
      isUserAdmin();
    }
  }, [props.instanceId]);

  React.useEffect(() => {
    initRemotes(stageConfig.legalAICompanion.url);
  }, []);

  return (
    <>
      {documentGroupId && isAdmin && (
        <div className={"pathfinder-chatbot"}>
          <ErrorBoundary fallbackRender={({ error }) => {
            return (
              <>
                <div style={{ whiteSpace: "pre-line" }}>Error loading chatbot</div>
                <div style={{ paddingTop: "10px" }}>
                  <p>
                    Please use Report a techinical bug to create SIM
                  </p>
                </div>
              </>
            );
          }}>
            <Suspense fallback={<Spinner />}>
              <LegalAiComponent
                {...legalAiCompanionProps}
                currentPageId={AI_COMPANION_DEFAULT_PAGE_ID}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
      )}
    </>
  );
}

function getDocumentGroupId(
  instanceId: string,
  mappings: Record<string, string>[]
): string | undefined {
  if (!mappings) return undefined;
  for (const mapping of mappings) {
    for (const key in mapping) {
      if (key.toLowerCase() === instanceId.toLowerCase()) {
        return mapping[key];
      }
    }
  }
  return undefined;
}

export default LegalAiCompanionComponent;
