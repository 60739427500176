import * as React from "react";
import {
  Box,
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  AdvancedListContent,
  FieldConfiguration,
  PrimitiveDataFactory,
} from "@amzn/ask-legal-domain";
import {
  FieldDefinition,
  FieldDefinitionDataType,
} from "@amzn/altar-sds-client";
import { UIModel } from "../../../../../../model/ui-model";
import { BooleanFieldAddEdit } from "../../fields/BooleanFieldAddEdit";
import { ChoiceFieldAddEdit } from "../../fields/ChoiceFieldAddEdit";
import { DateFieldAddEdit } from "../../fields/DateFieldAddEdit";
import { NumberFieldAddEdit } from "../../fields/NumberFieldAddEdit";
import { TextFieldAddEdit } from "../../fields/TextFieldAddEdit";
import { PersonFieldAddEdit } from "../../fields/PersonFieldAddEdit";
import { HyperlinkFieldAddEdit } from "../../fields/HyperlinkFieldAddEdit";
import { MultiPersonFieldAddEdit } from "../../fields/MultiPersonFieldAddEdit";
import { SequenceFieldAddEdit } from "../../fields/SequenceFieldAddEdit";

export interface ColumnAddEditModalProps {
  state: UIModel.State<AdvancedListContent>;
  forAdd?: boolean;
  type?: FieldDefinitionDataType;
  fieldDef?: FieldDefinition;
  fieldConfiguration?: FieldConfiguration.Item;
  onCreated?: (value: {
    fieldDef: FieldDefinition;
    fieldVisibility: boolean;
    fieldConfiguration?: FieldConfiguration.Item;
  }) => void;
  onUpdated?: (value: {
    fieldDef: FieldDefinition;
    fieldVisibility: boolean;
    fieldConfiguration?: FieldConfiguration.Item;
  }) => void;
  onCancel?: () => void;
}

export const ColumnAddEditModal = (props: ColumnAddEditModalProps) => {
  const [fieldDefiniton, setFieldDefintion] = React.useState<FieldDefinition>();
  const [fieldVisibility, setFieldVisibility] = React.useState<boolean>(true);
  const [fieldConfiguration, setFieldConfiguration] =
    React.useState<FieldConfiguration.Item>();

  React.useEffect(() => {
    if (props.fieldDef) {
      setFieldDefintion(props.fieldDef);
      setFieldVisibility(
        props.state.value.visibleFields?.includes(props.fieldDef.fieldKey)
      );
    }
    if (props.fieldConfiguration) {
      setFieldConfiguration(props.fieldConfiguration);
    }
  }, [props.fieldDef, props.fieldConfiguration]);

  const addNewColumn = (params: {
    fieldDef: FieldDefinition;
    fieldVisibility: boolean;
    fieldConfiguration?: FieldConfiguration.Item;
  }) => {
    const addFieldDefintion: FieldDefinition = {
      displayName: params.fieldDef.displayName!,
      description: params.fieldDef.description!,
      dataType: params.fieldDef.dataType!,
      fieldKey: PrimitiveDataFactory.id(4),
      deprecated: false,
      required: false,
      ...(params.fieldDef.choiceOptions && {
        choiceOptions: params.fieldDef.choiceOptions,
      }),
      ...(params.fieldDef.allowFreeTextOption && {
        allowFreeTextOption: params.fieldDef.allowFreeTextOption,
      }),
      ...(params.fieldDef.sequenceStartValue && {
        sequenceStartValue: params.fieldDef.sequenceStartValue
      })
    };

    setFieldDefintion(addFieldDefintion);
    setFieldVisibility(params.fieldVisibility);
    setFieldConfiguration(params.fieldConfiguration);
  };

  const updateContentByFieldType = () => {
    switch (props.fieldDef!.dataType!) {
      case FieldDefinitionDataType.boolean:
        return (
          <BooleanFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.choice:
        return (
          <ChoiceFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
              fieldConfiguration:
                fieldConfiguration as FieldConfiguration.Choice,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
              setFieldConfiguration(value.fieldConfiguration);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.date:
        return (
          <DateFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
              fieldConfiguration: fieldConfiguration as FieldConfiguration.Date,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
              setFieldConfiguration(value.fieldConfiguration);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.number:
        return (
          <NumberFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
              fieldConfiguration:
                fieldConfiguration as FieldConfiguration.Number,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
              setFieldConfiguration(value.fieldConfiguration);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.string:
        return (
          <TextFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.IdentityRef:
        return (
          <PersonFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.hyperlink:
        return (
          <HyperlinkFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.multiIdentityRef:
        return (
          <MultiPersonFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              setFieldDefintion(value.fieldDef);
              setFieldVisibility(value.fieldVisibility);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.sequence:
        return <SequenceFieldAddEdit
          value={{
            fieldDef: fieldDefiniton,
            fieldVisibility: fieldVisibility,
          }}
          onUpdated={(value) => {
            setFieldDefintion(value.fieldDef);
            setFieldVisibility(value.fieldVisibility);
          }}
          onCancel={props.onCancel}
        />;
      default:
        break;
    }
  };

  const addContentByFieldType = () => {
    switch (props.type) {
      case FieldDefinitionDataType.boolean:
        return (
          <BooleanFieldAddEdit
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.boolean,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
              });
            }}
          />
        );
      case FieldDefinitionDataType.choice:
        return (
          <ChoiceFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
              fieldConfiguration:
                fieldConfiguration as FieldConfiguration.Choice,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.choice,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
                fieldConfiguration: value.fieldConfiguration,
              });
            }}
          />
        );
      case FieldDefinitionDataType.date:
        return (
          <DateFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
              fieldConfiguration: fieldConfiguration as FieldConfiguration.Date,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.date,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
                fieldConfiguration: value.fieldConfiguration,
              });
            }}
          />
        );
      case FieldDefinitionDataType.number:
        return (
          <NumberFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
              fieldConfiguration:
                fieldConfiguration as FieldConfiguration.Number,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.number,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
                fieldConfiguration: value.fieldConfiguration,
              });
            }}
          />
        );
      case FieldDefinitionDataType.string:
        return (
          <TextFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.string,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
              });
            }}
          />
        );
      case FieldDefinitionDataType.IdentityRef:
        return (
          <PersonFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.IdentityRef,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
              });
            }}
          />
        );
      case FieldDefinitionDataType.hyperlink:
        return (
          <HyperlinkFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.hyperlink,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
              });
            }}
          />
        );
      case FieldDefinitionDataType.multiIdentityRef:
        return (
          <MultiPersonFieldAddEdit
            value={{
              fieldDef: fieldDefiniton,
              fieldVisibility: fieldVisibility,
            }}
            onUpdated={(value) => {
              const fieldDefinition: FieldDefinition = {
                ...value.fieldDef,
                dataType: FieldDefinitionDataType.multiIdentityRef,
              };
              addNewColumn({
                fieldDef: fieldDefinition,
                fieldVisibility: value.fieldVisibility!,
              });
            }}
          />
        );
      case FieldDefinitionDataType.sequence:
        return <SequenceFieldAddEdit
          value={{
            fieldDef: fieldDefiniton,
            fieldVisibility: fieldVisibility,
          }}
          onUpdated={(value) => {
            const fieldDefinition: FieldDefinition = {
              ...value.fieldDef,
              dataType: FieldDefinitionDataType.sequence,
            };
            addNewColumn({
              fieldDef: fieldDefinition,
              fieldVisibility: value.fieldVisibility!,
            });
          }}
        />;
    }
  };

  return (
    <Modal
      visible={true}
      header={<Header variant="h3">Configure Column</Header>}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={(e) => {
                props.onCancel?.();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                if (props.forAdd) {
                  props.onCreated?.({
                    fieldDef: fieldDefiniton,
                    fieldVisibility: fieldVisibility,
                    fieldConfiguration: fieldConfiguration,
                  });
                } else {
                  props.onUpdated?.({
                    fieldDef: fieldDefiniton,
                    fieldVisibility: fieldVisibility,
                    fieldConfiguration: fieldConfiguration,
                  });
                }
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {props.forAdd && <>{addContentByFieldType()}</>}

      {!props.forAdd && <>{updateContentByFieldType()}</>}
    </Modal>
  );
};
