import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import { ChoiceOption, ItemChoiceOption } from "@amzn/altar-sds-client";
import {
  Button,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  TokenGroup,
} from "@amzn/awsui-components-react";
import { AdvancedListConstants } from "../../../../utils/advanced-list.constant";

export interface SelectWithFreeTextProps {
  selectedOption?: ItemChoiceOption;
  options: ItemChoiceOption[];
  isFreeTextAllowed?: boolean;
  onChange?: (choiceOption?: ItemChoiceOption) => void;
}

export const SelectWithFreeText = (
  props: SelectWithFreeTextProps
) => {
  const [adhocMode, setAdhocMode] = React.useState<boolean>(false);

  const choiceOptionEditState = UIModel.State.use<ItemChoiceOption>({
    initialValue: props.selectedOption,
  });

  const adhocOptionState = UIModel.State.use<string>({
    initialValue: "",
    validation: (t: string) => {
      if (
        t.length > AdvancedListConstants.CHARACTER_LIMIT_OF_FREE_TEXT_OPTION
      ) {
        return `Length must not exceed ${AdvancedListConstants.CHARACTER_LIMIT_OF_FREE_TEXT_OPTION}`;
      }
      if (props.options?.find((o) => o.displayValue === t)) {
        return "Option must be unique";
      }
    },
  });

  const onAdhocOptionAdd = () => {
    if (adhocOptionState.errorText) {
      console.log(`not a valid data`, adhocOptionState.value);
      return;
    }
    const adhocOption: ItemChoiceOption = {
      displayValue: adhocOptionState.value.trim(),
    };
    choiceOptionEditState.setValue(
      adhocOption,
    );
    adhocOptionState.setValue("");
    setAdhocMode(false);
  };

  React.useEffect(() => {
    props.onChange?.(choiceOptionEditState.value);
  }, [choiceOptionEditState.value]);

  const toSelectOption = (choiceOption?: ItemChoiceOption | ChoiceOption) => {
    if (!!choiceOption) {
      return {
        label: choiceOption.displayValue,
        value: choiceOption.key!
      } as SelectProps.Option;
    }
  };

  return adhocMode ? (
    <SpaceBetween size="s">
      <FormField errorText={adhocOptionState.errorText}>
        <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
          <Input
            placeholder={"Enter another option"}
            value={adhocOptionState.value}
            autoFocus
            onChange={({ detail }) => adhocOptionState.setValue(detail.value)}
            onKeyDown={({ detail }) => {
              if (detail.keyCode === 13) {
                onAdhocOptionAdd();
              }
            }}
          />
          <SpaceBetween size="s" direction="horizontal">
            <Button
              variant="icon"
              iconName="close"
              onClick={() => {
                setAdhocMode(false);
                adhocOptionState.setValue("");
              }}
            />
            <Button
              disabled={
                !props.options?.find(
                  (o) => o.displayValue === adhocOptionState.value
                ) || adhocOptionState.value.length > 200
              }
              variant="icon"
              iconName="add-plus"
              onClick={onAdhocOptionAdd}
            />
          </SpaceBetween>
        </Grid>
      </FormField>
    </SpaceBetween>
  ) : (
    <>
      <Select
        filteringType="auto"
        placeholder="Choose an applicable option"
        empty="No options found"
        selectedOption={toSelectOption(props.options?.find((o) => o.key === choiceOptionEditState.value?.key))}
        options={[
          ...props.options.map((o) => ({
            value: o.key ?? o.displayValue,
            label: o.displayValue
          })),
          ...(props.isFreeTextAllowed ? [{
            value: "other",
            label: "Add other option"
          }] : [])
        ]}
        onChange={({ detail }) => {
          if (detail.selectedOption.value === "other") {
            setAdhocMode(true);
          } else {
            const found = props.options?.find((o) => o.key === detail.selectedOption.value);
            choiceOptionEditState.setValue(found);
          }
        }}
      />
      <TokenGroup
        items={
          choiceOptionEditState?.value
          ? [{ label: choiceOptionEditState?.value?.displayValue }]
          : []
        }
        onDismiss={({ detail }) => {
          choiceOptionEditState?.setValue(undefined);
        }}
      />
    </>
  );
};
