import { Header, Popover, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { AskLegalLayoutUtils } from "../../layout/AskLegalLayout";
import { ContainerGeneralInfo } from "../ContainerGeneralInfo";
import { ContainerLinkCopyButton } from "../ContainerButtons";
import { HashLink } from "react-router-hash-link";
import { UIModel } from "../../../model/ui-model";
import { BaseContainerHeaderProps } from "./BaseContainerHeaderPropsInterface";
const BACK_TO_TOP = require("../../../assets/back-to-top.png").default;

export namespace ContainerReviewModeHeader {
    export interface Props extends BaseContainerHeaderProps {
        showDiff: UIModel.State<boolean>;
        showCompareFeature?: boolean;
    }

    export const Comp = (
        props: Props
    ) => {

        return (
            <Header
                actions={
                    <SpaceBetween direction="horizontal" size="xxxs" className="awsui-polaris-dark-mode horizontal-center">
                        {!!props.showCompareFeature && !props.loading &&
                            <span
                                title="Compare with live"
                                onClick={props.onHeaderClicked}
                            >
                                <Toggle
                                    onChange={({ detail }) => props.showDiff.setValue(detail.checked)}
                                    checked={props.showDiff.value}
                                ><b>Compare</b></Toggle>
                            </span>}
                        {props.loading && <Spinner />}
                        <span onClick={props.onHeaderClicked}>
                            <ContainerLinkCopyButton containerId={props.containerMetaData.id} />
                        </span>
                        <span title="Navigate to top" onClick={props.onHeaderClicked}>
                            <a style={{ textDecoration: "none" }}>
                                <HashLink
                                    smoothed to={"#"}
                                    style={{ textDecoration: "none" }}
                                    scroll={el => AskLegalLayoutUtils.scrollWithOffset(el, -80)}>
                                    <span><img height="28px" src={BACK_TO_TOP} /></span>
                                </HashLink>
                            </a>
                        </span>
                    </SpaceBetween>}
                info={
                    <span onClick={props.onHeaderClicked}>
                        <Popover
                            position="bottom"
                            size="large"
                            triggerType="custom"
                            header={"Container Info"}
                            content={
                                <ContainerGeneralInfo data={props.containerMetaData} />
                            }>
                            <span title="info" style={{ color: "white", cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                            </span>
                        </Popover>
                    </span>
                }>
                <p className="dark-header-p">
                    {props.containerMetaData.title}
                </p>
            </Header>
        );
    };
}