import * as React from "react";
import {
  Button,
  FormField,
  FormFieldProps,
  Header,
  Input,
  InputProps,
  RadioGroup,
  SpaceBetween,
  Textarea,
  TextareaProps,
  Toggle,
  TokenGroup,
} from "@amzn/awsui-components-react";
import { FieldDefinition, ChoiceOption } from "@amzn/altar-sds-client";
import { UIField } from "../../../../common/UIField";
import { FieldConfiguration } from "@amzn/ask-legal-domain";
import { UIModel } from "../../../../../model/ui-model";
import {
  ChoiceOptionAdd,
  ChoiceOptionsEdit,
} from "../../../../advanced-list/fields/ChoiceFields/ChoiceOptionInput";

export const ChoiceFieldAddEdit = (props: {
  value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
    fieldConfiguration?: FieldConfiguration.Choice;
  };
  onUpdated?: (value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
    fieldConfiguration?: FieldConfiguration.Choice;
  }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value?.fieldDef?.displayName,
  });
  const [fieldColumnDescription, setFieldColumnDescription] =
    React.useState<FormFieldProps>({});
  const [columnDescription, setColumnDescription] = React.useState<TextareaProps>({
    value: props.value?.fieldDef?.description ?? "",
  });

  const [showOnLivePage, setShowOnLivePage] = React.useState<boolean>(true);
  const [allowFreeTextOption, setAllowFreeTextOption] = React.useState<boolean>(
    props.value?.fieldDef?.allowFreeTextOption ?? false
  );

  const [fieldChoiceType, setFieldChoiceType] = React.useState<FormFieldProps>(
    {}
  );
  const [fieldChoiceOptions, setFieldChoiceOptions] =
    React.useState<FormFieldProps>({});

  const [choiceFieldConfig, setChoiceFieldConfig] =
    React.useState<FieldConfiguration.Choice>({
      type: FieldConfiguration.ChoiceType.SINGLE,
    });
  const choiceOptionState = UIModel.State.use<ChoiceOption[]>({
    initialValue: [],
  });

  const [addChoiceOptionsEnabled, setAddChoiceOptionsEnabled] =
    React.useState<boolean>(false);
  const [editChoiceOptionsEnabled, setEditChoiceOptionsEnabled] =
    React.useState<boolean>(false);

  function validate() {
    let valid = true;
    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      valid = false;
    }

    if (choiceFieldConfig?.type?.trim().length > 0) {
      setFieldChoiceType({
        errorText: "",
      });
    } else {
      setFieldChoiceType({
        errorText: "Choice type selection is required",
      });
      valid = false;
      return;
    }

    if (choiceOptionState.value.length <= 0) {
      setFieldChoiceOptions({
        errorText: "Add atleast one choice option",
      });
      valid = false;
      return;
    } else {
      setFieldChoiceOptions({
        errorText: "",
      });
    }

    if (valid) {
      props.onUpdated?.({
        fieldDef: {
          ...props.value.fieldDef,
          displayName: columnDisplayName.value!,
          allowFreeTextOption: allowFreeTextOption,
          choiceOptions: choiceOptionState.value,
          description: columnDescription.value?.length ? columnDescription.value : undefined,
        },
        fieldConfiguration: {
          ...props.value.fieldConfiguration,
          type: choiceFieldConfig.type,
        },
        fieldVisibility: showOnLivePage,
      });
    }
  }
  React.useEffect(() => {
    validate();
  }, [
    columnDisplayName.value,
    columnDescription.value,
    choiceFieldConfig.type,
    choiceOptionState.value,
    showOnLivePage,
    allowFreeTextOption,
  ]);

  React.useEffect(() => {
    if (!props.value?.fieldDef) return;
    setColumnDisplayName({ value: props.value.fieldDef.displayName });
    setColumnDescription({ value: props.value.fieldDef.description });
    setAllowFreeTextOption(props.value.fieldDef.allowFreeTextOption ?? false);

    setChoiceFieldConfig({
      type: props.value?.fieldConfiguration?.type ?? FieldConfiguration.ChoiceType.SINGLE,
    });

    choiceOptionState.setValue(props.value.fieldDef.choiceOptions ?? []);

    setShowOnLivePage(props.value.fieldVisibility);
  }, [props.value]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Choice Column Details</Header>
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />
        <UIField.CustomField
          name={"Column Info"}
          description={"Describe how the user should fill this field"}
          errorText={fieldColumnDescription.errorText}
          child={
            <Textarea
              value={columnDescription.value}
              onChange={(e) => setColumnDescription({ value: e.detail.value })}
              placeholder={"Enter Column Info"}
            />
          }
        />
        <UIField.CustomField
          name="Choice Column Type"
          errorText={fieldChoiceType.errorText}
          child={
            <RadioGroup
              items={[
                { value: FieldConfiguration.ChoiceType.SINGLE, label: "Single" },
                { value: FieldConfiguration.ChoiceType.MULTIPLE, label: "Multiple" },
              ]}
              value={choiceFieldConfig.type}
              onChange={(e) =>
                setChoiceFieldConfig({
                  type: e.detail.value,
                })
              }
            />
          }
        />

        <SpaceBetween direction="horizontal" size="m">
          <Header
            headingTagOverride="h4"
            actions={
              <>
                <Button
                  variant="inline-icon"
                  iconName="edit"
                  disabled={props.value?.fieldDef?.choiceOptions?.length <= 0}
                  onClick={(e) => {
                    setEditChoiceOptionsEnabled(true);
                    setAddChoiceOptionsEnabled(false);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="inline-icon"
                  iconName="add-plus"
                  onClick={(e) => {
                    setEditChoiceOptionsEnabled(false);
                    setAddChoiceOptionsEnabled(true);
                  }}
                >
                  Add
                </Button>
              </>
            }
          >
            Choice Options:
          </Header>
          <FormField errorText={fieldChoiceOptions.errorText} />
        </SpaceBetween>

        {!addChoiceOptionsEnabled && !editChoiceOptionsEnabled && (
          <TokenGroup
            items={[
              ...choiceOptionState.value.map((choice) => ({
                label: choice.displayValue,
                value: choice.key,
              })),
            ]}
          />
        )}

        {addChoiceOptionsEnabled && (
          <ChoiceOptionAdd
            choiceOptions={choiceOptionState.value}
            onConfirm={(updatedChoiceOptions: ChoiceOption[]) => {
              choiceOptionState.setValue(updatedChoiceOptions);
              setAddChoiceOptionsEnabled(false);
            }}
            onCancel={() => {
              setAddChoiceOptionsEnabled(false);
            }}
          />
        )}

        {editChoiceOptionsEnabled && (
          <ChoiceOptionsEdit
            choiceOptions={choiceOptionState.value}
            onConfirm={(updatedChoiceOptions: ChoiceOption[]) => {
              choiceOptionState.setValue(updatedChoiceOptions);
              setEditChoiceOptionsEnabled(false);
            }}
            onCancel={() => {
              setEditChoiceOptionsEnabled(false);
            }}
          />
        )}

        <SpaceBetween direction="vertical" size="xxs">
          <Header headingTagOverride="h4">Options</Header>
          <SpaceBetween size="s" direction="vertical">
            <Toggle
              checked={showOnLivePage}
              onChange={({ detail }) => {
                setShowOnLivePage(detail.checked);
              }}
            >
              Show column on live page
            </Toggle>
            <Toggle
              checked={allowFreeTextOption}
              onChange={({ detail }) => {
                setAllowFreeTextOption(detail.checked);
              }}
            >
              Allow free text options
            </Toggle>
          </SpaceBetween>
        </SpaceBetween>
      </SpaceBetween>
    </SpaceBetween>
  );
};
