import { Icon } from "@amzn/awsui-components-react";
import * as React from "react";
import { useState } from "react";
import "../../styles/component/layout/PageBaseLayout.scss";

export const PageBaseLayout = (props: {
    mainContent: JSX.Element;
    rightContent: JSX.Element;
    expandable?: boolean;
    hideRightSection?: boolean;
}) => {
    const [rightSectionExpanded, setRightSectionExpanded] = useState<boolean>(true);

    const sectionDivider = props.expandable ? (
        <div className="section-divider" onClick={() => setRightSectionExpanded(!rightSectionExpanded)}>
            { rightSectionExpanded ? <Icon name="angle-right-double"/> : <Icon name="angle-left-double"/> }
            <div className="vertical-line"/>
        </div>
    ) : (
        <div className="section-divider">
            <div className="dividedText"></div>
        </div>
    );

    React.useEffect(() => {
        if (!props.expandable) return;
        if (props.hideRightSection) setRightSectionExpanded(false);
    }, []);

    return (
        <div className="page-layout">
            <div className={rightSectionExpanded ? "page-layout-main open" : "page-layout-main closed"}>
                {props.mainContent}
            </div>
            {!props.hideRightSection && sectionDivider}
            <div className={rightSectionExpanded ? "page-layout-right open" : "page-layout-right closed"}>
                {props.rightContent}
            </div>
        </div>
    );
};