import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import { ItemChoiceOption } from "@amzn/altar-sds-client";
import {
  Button,
  FormField,
  Grid,
  Input,
  Multiselect,
  SpaceBetween,
  TokenGroup,
} from "@amzn/awsui-components-react";
import { AdvancedListConstants } from "../../../../utils/advanced-list.constant";

export interface MultiselectWithFreeTextProps {
  selectedOptions: ItemChoiceOption[];
  options: ItemChoiceOption[];
  isFreeTextAllowed?: boolean;
  onChange?: (choiceOptionList?: ItemChoiceOption[]) => void;
}

export const MultiselectWithFreeText = (
  props: MultiselectWithFreeTextProps
) => {
  const [adhocMode, setAdhocMode] = React.useState<boolean>(false);

  const choiceOptionsEditState = UIModel.State.use<ItemChoiceOption[]>({
    initialValue: [...props.selectedOptions],
  });

  const adhocOptionState = UIModel.State.use<string>({
    initialValue: "",
    validation: (t: string) => {
      if (
        t.length > AdvancedListConstants.CHARACTER_LIMIT_OF_FREE_TEXT_OPTION
      ) {
        return `Length must not exceed ${AdvancedListConstants.CHARACTER_LIMIT_OF_FREE_TEXT_OPTION}`;
      }
      if (choiceOptionsEditState?.value?.find((o) => o.displayValue === t)) {
        return "Option must be unique";
      }
    },
  });

  const onAdhocOptionAdd = () => {
    if (adhocOptionState.errorText) {
      console.log(`not a valid data`, adhocOptionState.value);
      return;
    }
    const adhocOption: ItemChoiceOption = {
      displayValue: adhocOptionState.value.trim(),
    };
    choiceOptionsEditState.setValue([
      ...choiceOptionsEditState.value,
      adhocOption,
    ]);
    adhocOptionState.setValue("");
    setAdhocMode(false);
  };

  React.useEffect(() => {
    props.onChange?.(choiceOptionsEditState.value);
  }, [choiceOptionsEditState.value]);

  return adhocMode ? (
    <SpaceBetween size="s">
      <FormField errorText={adhocOptionState.errorText}>
        <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
          <Input
            placeholder={"Enter another option"}
            value={adhocOptionState.value}
            autoFocus
            onChange={({ detail }) => adhocOptionState.setValue(detail.value)}
            onKeyDown={({ detail }) => {
              if (detail.keyCode === 13) {
                onAdhocOptionAdd();
              }
            }}
          />
          <SpaceBetween size="s" direction="horizontal">
            <Button
              variant="icon"
              iconName="close"
              onClick={() => {
                setAdhocMode(false);
                adhocOptionState.setValue("");
              }}
            />
            <Button
              disabled={
                props.options?.find(
                  (o) => o.displayValue === adhocOptionState.value
                ) || adhocOptionState.value.length > 200
              }
              variant="icon"
              iconName="add-plus"
              onClick={onAdhocOptionAdd}
            />
          </SpaceBetween>
        </Grid>
      </FormField>
    </SpaceBetween>
  ) : (
    <>
      <Multiselect
        selectedOptions={[
          ...choiceOptionsEditState.value
          .map((o) => {
            return {
              value: props.options.find((s) => s.key === o.key)?.key,
              label: props.options.find((s) => s.key === o.key)?.displayValue,
            };
          }),
        ]}
        options={[
          ...props.options.map((o) => ({
            label: o.displayValue,
            value: o.key,
          })),
          ...(props.isFreeTextAllowed
            ? [
                {
                  label: "Other",
                  value: "other",
                  description: "Add your own option",
                },
              ]
            : []),
        ]}
        onChange={({ detail }) => {
          if (detail.selectedOptions.find((o) => o.value === "other")) {
            setAdhocMode(true);
          } else {
            choiceOptionsEditState.setValue([
              ...detail.selectedOptions.map((o) => ({
                displayValue: o.label!,
                key: o.value!,
              })),
            ]);
          }
        }}
        hideTokens={true}
        filteringType="auto"
        placeholder="Choose all applicable options"
        empty="No options found"
      />
      <TokenGroup
        items={choiceOptionsEditState?.value?.map((o) => {
          return {
            value: o.key,
            label: o.displayValue,
          };
        })}
        onDismiss={({ detail: { itemIndex } }) => {
          choiceOptionsEditState?.setValue([
            ...choiceOptionsEditState?.value?.slice(0, itemIndex),
            ...choiceOptionsEditState?.value?.slice(itemIndex + 1),
          ]);
        }}
      />
    </>
  );
};
