export namespace FileUtils {
    export enum Extension {
        TXT = "txt",
        XLSX = "xlsx",
        XLS = "xls",
        DOCX = "docx",
        PPTX = "pptx",
        CSV = "csv",
        PDF = "pdf",
        JSON = "json",
        PNG = "png",
        JPG = "jpg",
        JPEG = "jpeg",
        XML = "xml",
        HTML = "html",
        NOEXTENSION = "noextension"
    }

    export function getExtension(filename: string): Extension {
        const lastDotIndex = filename.lastIndexOf(".");
        if (lastDotIndex === -1) return Extension.NOEXTENSION;
        return filename.slice(lastDotIndex + 1).toLowerCase() as Extension;
    }

    export function getFileNameWithoutExtension(filename: string): string {
        const lastDotIndex = filename.lastIndexOf(".");
        return lastDotIndex === -1 ? filename : filename.slice(0, lastDotIndex);
    }

    export function getFileNameWithExtension(filename: string, extension: Extension): string {
        return `${filename}.${extension}`;
    }
}