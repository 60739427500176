import {
    AdvancedList,
    EntityRef,
    EntityExtraRef,
    LoadAdvancedListCommandOutput,
    Pagination as SDS_Pagination,
    SearchAdvancedListItemsCommandOutput,
    AdvancedListSearchItem,
    JoinOperation,
    ComparisonOperator
} from "@amzn/altar-sds-client";
import {
    APIOutput,
    FieldConfiguration
} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Flashbar,
    FlashbarProps,
    Header,
    Modal,
    SpaceBetween,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { Builder } from "builder-pattern";
import { AdvancedListFactory } from "../../../factory/advanced-list-factory";
import { SDSUtils } from "../../../utils/sds-utils";

const DEFAULT_PAGE_SIZE = 100;

export function ExportAdvancedListItemsModal(props: {
    advancedListEntityRef: EntityRef;
    fieldConfigurations?: FieldConfiguration.Record;
    selectedItems?: EntityExtraRef[];
    onCanceled?: () => void;
    onConfirm?: () => void;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [advancedList, setAdvancedList] = React.useState<AdvancedList>();
    const [totalItems, setTotalItems] = React.useState<number|undefined>();
    const [advancedListSearchItems, setAdvancedListSearchItems] = React.useState<AdvancedListSearchItem[]>([]);

    async function init() {
        try {
            setSpinnerProps({});
            // Load Advanced List
            const loadOutput = await context.getAdvancedListAPI().load({
                entityId: props.advancedListEntityRef.entityId,
                repositoryId: props.advancedListEntityRef.repositoryRef.repositoryId,
                by: SDSUtils.getAmazonPersonRef(context.userId)
            });

            let advancedList: AdvancedList;
            const output = APIOutput.fromRaw<LoadAdvancedListCommandOutput>(loadOutput.data);
            if (output.isOk()) {
                advancedList = Builder(output.data.body)
                    .fieldDefinitions(output.data.body.fieldDefinitions)
                    .build();

                setAdvancedList(advancedList);
            } else if (output.isErr()) {
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
                return;
            }

            // Search all advanced list items
            let allAdvancedListItems: AdvancedListSearchItem[] = [];
            let totalItemsToFetch: number = 0;
            const pageSize = DEFAULT_PAGE_SIZE;
            let pageIndex = 0;
            do {
                const searchItemsOutput = await context.getAdvancedListAPI().searchItems({
                    listEntityId: props.advancedListEntityRef.entityId,
                    listRepositoryId: props.advancedListEntityRef.repositoryRef.repositoryId,
                    pagination: Builder<SDS_Pagination>()
                        .pageSize(pageSize)
                        .pageIndex(pageIndex + 1)
                        .build(),
                    by: SDSUtils.getAmazonPersonRef(context.userId),
                    ...props.selectedItems?.length && {
                        query: {
                            operation: JoinOperation.AND,
                            propertyTokens: [
                                {
                                    name: "entityExtraVersionRef.entityExtraRef.extraId",
                                    value: props.selectedItems?.map((item) => item.extraId!) ?? [],
                                    operator: ComparisonOperator.EQUALS
                                }
                            ]
                        },
                    },
                });

                const output = APIOutput.fromRaw<SearchAdvancedListItemsCommandOutput>(searchItemsOutput.data);
                if (output.isOk()) {
                    totalItemsToFetch = output.data.total;
                    setTotalItems(totalItemsToFetch);
                    allAdvancedListItems = [...allAdvancedListItems, ...output.data.items];
                    setAdvancedListSearchItems(allAdvancedListItems);
                    pageIndex++;
                }
            } while (allAdvancedListItems.length < totalItemsToFetch);
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    React.useEffect(() => {
        init();
    }, [props.advancedListEntityRef]);

    return (
        <Modal
            visible={true}
            header={<Header>Export Advanced List Items</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween size={"s"} direction="horizontal">
                        <Button
                            onClick={() => {
                                props.onCanceled?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={totalItems !== advancedListSearchItems.length}
                            onClick={() => {
                                AdvancedListFactory.exportItemsToFile({
                                    fieldDefinitions: advancedList?.fieldDefinitions ?? [],
                                    fieldConfigurationRecord: props.fieldConfigurations ?? {},
                                    items: advancedListSearchItems
                                });
                                props.onConfirm?.();
                            }}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => props.onCanceled?.()}
        >
            {spinnerProps && (
                <Spinner size="normal"/>
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            <SpaceBetween direction="vertical" size="m">
                {totalItems && `Fetched ${advancedListSearchItems.length} / ${totalItems} records...`}
                {(totalItems === advancedListSearchItems.length) && (
                    <>
                        File is ready to be exported. Click on Confirm to save the Excel file.
                    </>
                )}
            </SpaceBetween>
        </Modal>
    );
}
