import * as React from "react";
import { BootstrapComp } from "../../common/BootstrapComp";
import {
    ContainerFactory,
    Document,
    DocumentContent,
    EntityFactory,
    EntityRef,
    Identity,
    IFile,
    InstanceFactory,
    IPageDraft,
    Label,
    S3File,
    ServerSidePaginatedLoadingInput
} from "@amzn/ask-legal-domain";
import {
    Alert,
    Badge,
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    ColumnLayout,
    Flashbar,
    Grid,
    Popover,
    Select,
    SelectProps,
    SpaceBetween,
    Spinner,
    TextContent
} from "@amzn/awsui-components-react";
import { useContext } from "react";
import { FileRejection } from "react-dropzone";
import { useAPI, useAPI2 } from "../../../hooks/api-hook";
import { ContainerModel } from "../../../model/container-model";
import { PageDraftModel } from "../../../model/page-draft-model";
import { UIModel } from "../../../model/ui-model";
import { AppContext } from "../../../setup/context";
import { FileUpload } from "../../common/FileUpload";
import { UIField } from "../../common/UIField";
import { Builder } from "builder-pattern";
import { DocumentGroupExpandable } from "../document-group/DocumentGroupView";
import { DocumentIcon } from "../../common/DocumentIcon";
import { LabelAssignModal } from "../../label/LabelAssignModal";
import { documentsComparator } from "../../../utils/common-utils";
import "../../../styles/component/document-group.scss";
const WORD_ICON = require("../../../assets/file-icons/doc-icon.png").default;
const EXCEL_ICON = require("../../../assets/file-icons/xls-icon.png").default;
const PPT_ICON = require("../../../assets/file-icons/ppt-icon.png").default;
const PDF_ICON = require("../../../assets/file-icons/pdf-icon.png").default;
const HTML_ICON = require("../../../assets/file-icons/html-icon.png").default;
const PNG_ICON = require("../../../assets/file-icons/png-icon.png").default;
const JPG_ICON = require("../../../assets/file-icons/jpg-icon.png").default;
const TXT_ICON = require("../../../assets/file-icons/txt-icon.png").default;
const COMMON_FILE_ICON = require("../../../assets/file-icons/general-icon.png").default;
const CSV_ICON = require("../../../assets/file-icons/csv-icon.png").default;
const ICON_HEIGHT = 40;

/**
 * TODO: Component needs major refactor with new functionalities in place.
 * Need to avoid re rendering every component based on state changes
 */
export const DocumentUploadFileModal = (props: {
    draftRef: EntityRef;
    containerId: string;
    loading: boolean;
    disabled?: boolean;
    onClose: () => void;
}) => {
    const context = useContext(AppContext);
    const loadContainerRunner = useAPI(
        context.getContainerAPI().load
    );
    const editContainerRunner = useAPI(
        context.getPageDraftAPI().editContainer
    );

    const state = PageDraftModel.EditContainerState.use({
        draftRef: props.draftRef
    });

    React.useEffect(() => {
        loadContainerRunner.submitRun(props.containerId);
    }, []);

    const update = (updatedContent: DocumentContent) => {
        editContainerRunner.submitRun(
            IPageDraft.EditContainerInput.create({
                newContainerContent: updatedContent,
                newTitle: state.titleField.value,
                newDescription: state.descriptionField.value,
                draftRef: state.draftRef,
                containerRef: ContainerFactory.toContainerRef(state.activeContainerField.value)
            })
        );
    };

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            state.init({
                container: loadContainerRunner.data.output.containerMetadata,
                containerContent: loadContainerRunner.data.output.loadedContent
            });
        }
    }, [loadContainerRunner.status]);

    React.useEffect(() => {
        if (editContainerRunner.status === "Succeeded") {
            state.draftRef = EntityFactory.toEntityRef(editContainerRunner.data.output);
            loadContainerRunner.reload();
        }
    }, [editContainerRunner.status]);

    return (
        <BootstrapComp.CustomModal
            visible={true}
            size="lg"
            header="Upload Document"
            body={
                <DocumentUploadFileModalBody
                    containerId={props.containerId}
                    state={state}
                    update={update}
                    loading={editContainerRunner.status === "Running" || loadContainerRunner.status === "Running"}
                />
            }
            footer={
                <Box float="right">
                    <Button variant="link" loading={props.loading} onClick={props.onClose}>
                        Close
                    </Button>
                </Box>
            }
            onDismiss={props.onClose} />
    );
};

export const DocumentUploadFileModalBody = (props: {
    containerId: string;
    state: PageDraftModel.EditContainerState;
    update: (updatedContent: DocumentContent) => void;
    loading: boolean;
}) => {
    const context = useContext(AppContext);
    const [userIdentity, setUserIdentity] = React.useState<Identity>(null);
    const [duplicateDocument, setDuplicateDocument] = React.useState<Document>(null);
    const [selectedDocumentGroup, setSelectedDocumentGroup] = React.useState<SelectProps.Option>(null);
    const [attachLabelModalOpenFileKey, setAttachLabelModalOpenFileKey] = React.useState<S3File.AskLegalFileKey>(null);
    const [descriptionConstraint, setDescriptionConstraint] = React.useState<React.ReactNode>(null);
    const [resourceIdOfUpdatedLabels, setResourceIdOfUpdatedLabels] = React.useState<S3File.AskLegalFileKey>(null);

    const errorMsg = UIModel.State.use<string[]>({
        initialValue: []
    });
    const uploadDocumentState = ContainerModel.UploadDocumentState.use({
        containerId: props.containerId
    });
    const getPresignedUrlRunner = useAPI(
        context.getFileAPI().getPresignedUrl
    );
    const uploadFileViaPresignedUrlRunner = useAPI(
        context.getFileAPI().uploadViaPresignedUrl
    );
    const onFileDrop = (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        event: any
    ) => {
        const msg: string[] = [];
        if (rejectedFiles.length > 0) {
            rejectedFiles.forEach((rejectedFile) => {
                msg.push(
                    `${rejectedFile.file.name
                    } cannot be uploaded, reason: ${JSON.stringify(
                        rejectedFile.errors.map((err) => err.code)
                    )}`
                );
            });
            errorMsg.setValue(msg);
        } else if (rejectedFiles.length === 0) {
            errorMsg.setValue([]);
        }
        if (acceptedFiles.length === 0) {
            return;
        }
        else if (acceptedFiles.length > 1) {
            msg.push(
                `Only one file at a time`
            );
        }
        if ((props.state.contentField.value as DocumentContent).files.some(e => e.filename === acceptedFiles[0].name)) {
            setDuplicateDocument(
                (props.state.contentField.value as DocumentContent).files
                    .find(e => e.filename === acceptedFiles[0].name)
            );
        } else {
            setDuplicateDocument(null);
        }
        uploadDocumentState.fileField.setValue(acceptedFiles[0]);
    };

    const onRemove = (f: Document, groupTitle?: string) => {
        props.update(DocumentContent.removeFile(
            props.state.contentField.value as DocumentContent,
            f.filename,
            (!!groupTitle && groupTitle.length > 0) ? groupTitle : undefined
        ));
    };

    const onUpload = () => {
        if (!uploadDocumentState.s3fileKey) return;
        getPresignedUrlRunner.submitRun(
            IFile.PresignedUrlInput.create({
                key: uploadDocumentState.s3fileKey,
                operation: IFile.PresignedOperation.Put,
            })
        );
    };

    const onMove = (doc: Document, action: "moveUp" | "moveDown" , groupTitle?: string) => {
        props.update(DocumentContent.moveFile(
            props.state.contentField.value as DocumentContent,
            doc.filename,
            action,
            groupTitle
        ));
    };

    const onCancelUpload = () => {
        uploadDocumentState.reset();
    };

    const fetchIdentity = async () => {
        let currentUser: Identity;
        try {
            currentUser = await context.getIdentity();
        } catch (err) {
            console.warn(err);
        }
        if (!currentUser) {
            console.warn("Error fetching user on file upload");
            setUserIdentity(null);
        }
        setUserIdentity(currentUser);
    };

    const onItemClickHandler = (actionId: string, document: Document, groupTitle?: string) => {
        switch (actionId) {
            case "manageLabels":
                setAttachLabelModalOpenFileKey(document.s3File.key);
                break;
            case "remove":
                onRemove(document, groupTitle);
                break;
            case "moveUp":
                onMove(document, "moveUp", groupTitle);
                break;
            case "moveDown":
                onMove(document, "moveDown", groupTitle);
                break;
            default:
                break;
        }
    };

    const actionButtonDefaultItems: ButtonDropdownProps.Items = [
        { text: "Manage labels", id: "manageLabels" },
        { text: "Remove", id: "remove" },
    ];

    const actionButtonSortItems: ButtonDropdownProps.Items = [{
            text: "Re-Order",
            items: [
                { text: "Move Up", id: "moveUp" },
                { text: "Move Down", id: "moveDown" }
            ]
        }];

    const compActionButtonDropdown = (document: Document, groupTitle?: string) => <ButtonDropdown
        loading={props.loading}
        items={
            !(props.state.contentField.value as DocumentContent)?.manualSort ?
                actionButtonDefaultItems : actionButtonDefaultItems.concat(actionButtonSortItems)
        }
        onItemClick={(e) => onItemClickHandler(e.detail.id, document, groupTitle)}
    >
        Actions
    </ButtonDropdown>;

    React.useEffect(() => {
        if (getPresignedUrlRunner.status === "Succeeded") {
            uploadFileViaPresignedUrlRunner.submitRun({
                data: uploadDocumentState.fileField.value,
                presignedUrl: getPresignedUrlRunner.data.output.presignedUrl
            });
        }
    }, [getPresignedUrlRunner.status]);

    React.useEffect(() => {
        if (uploadFileViaPresignedUrlRunner.status === "Succeeded") {
            const versionId = uploadFileViaPresignedUrlRunner.data.output.headers["x-amz-version-id"];
            const s3File = S3File.Data.create({
                bucket: getPresignedUrlRunner.data.output.bucket,
                key: uploadDocumentState.s3fileKey,
                versionId: versionId,
                contentType: S3File.ContentType.ContainerRichTextContent
            });
            props.update(DocumentContent.addOrReplaceFile(
                props.state.contentField.value as DocumentContent,
                ContainerModel.UploadDocumentState.toDocument(
                    uploadDocumentState, s3File, userIdentity
                ),
                (!!selectedDocumentGroup) ? selectedDocumentGroup.value : undefined
            ));
            uploadDocumentState.reset();
        }
    }, [uploadFileViaPresignedUrlRunner.status]);

    React.useEffect(() => {
        const descriptionLength = uploadDocumentState.descriptionField.value ? uploadDocumentState.descriptionField.value.length : 0;
        const charLimitCheck = ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT - descriptionLength;
        if (charLimitCheck === ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT) {
            setDescriptionConstraint(
                `Maximum ${ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT} characters`
            );
        } else if (charLimitCheck >= 0) {
            setDescriptionConstraint(
                `Maximum ${ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT} characters ( ${ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT - descriptionLength} remaining )`
            );
        } else if (charLimitCheck < 0) {
            setDescriptionConstraint(
                <TextContent>
                    <small>
                        Maximum {ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT} characters ( <span style={{ color: "#d13212" }}>{descriptionLength - ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT}</span> too many )
                    </small>
                </TextContent>
            );
        }

    }, [uploadDocumentState.descriptionField.value]);

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    return (
        <React.Fragment>
            {attachLabelModalOpenFileKey &&
                <LabelAssignModal
                    resourceId={S3File.AskLegalFileKey.toString(attachLabelModalOpenFileKey)}
                    resourceType="Document"
                    instanceId={InstanceFactory.fromEntityId(props.containerId)}
                    onCloseModal={() => {
                        setResourceIdOfUpdatedLabels(attachLabelModalOpenFileKey);
                        setAttachLabelModalOpenFileKey(null);
                    }}
                />
            }
            <SpaceBetween direction="vertical" size="xl">
                <ColumnLayout borders="horizontal" columns={1}>
                    <div>
                        {!uploadDocumentState.fileField.value &&
                            <FileUpload.Comp onDrop={onFileDrop} acceptedFileTypes={{
                                "image/*": [".png", ".jpeg", ".jpg"],
                                "text/plain": [".txt"],
                                "text/html": [".html"],
                                "text/csv": [".csv"],
                                "application/pdf": [".pdf"],
                                "application/msword": [".doc"], // MS Word
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"], // MS Word (Open XML)
                                "application/vnd.ms-excel": [".xls"], // MS Excel
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"], // MS Excel (Open XML)
                                "application/vnd.ms-powerpoint": [".ppt"], // MS Powerpoint
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"], // MS Powerpoint (Open XML)
                                "message/rfc822": [".eml"],
                                "application/vnd.ms-outlook": [".msg", ".oft"],
                                "application/octet-stream": [".emltpl"],
                            }} maxFiles={1} />
                        }
                        {!!uploadDocumentState.fileField.value && !!duplicateDocument && (
                            <Flashbar items={[{
                                type: "info",
                                dismissible: false,
                                content: (
                                    <>
                                        A file with the same file name already exists. Uploading will overwrite the existing file.
                                    </>
                                ),
                                action: (
                                    <Popover
                                        dismissAriaLabel="Close"
                                        fixedWidth
                                        header="File to be replaced"
                                        size="large"
                                        triggerType="custom"
                                        content={
                                            <SpaceBetween size="l">
                                                <UIField.LabelField
                                                    label={"File Name"}>
                                                    {duplicateDocument.filename}
                                                </UIField.LabelField>
                                                <UIField.LabelField
                                                    label={"Description"}>
                                                    {duplicateDocument.description}
                                                </UIField.LabelField>
                                                <UIField.LabelField
                                                    label={"Last Uploaded on"}>
                                                    {duplicateDocument.uploadedDate.toLocaleString()}
                                                </UIField.LabelField>
                                                <UIField.LabelField
                                                    label={"Last Uploaded by"}>
                                                    {duplicateDocument.uploadedBy.name}
                                                </UIField.LabelField>
                                            </SpaceBetween>
                                        }
                                    >
                                        <Button>Details</Button>
                                    </Popover>
                                )
                            }]} />
                        )}
                        {!!uploadDocumentState.fileField.value && (
                            <Alert
                                dismissible={false}
                                header="Complete the upload">
                                <ColumnLayout columns={3}>
                                    <UIField.LabelField
                                        label={"File name"}>
                                        {uploadDocumentState.fileField.value.name}
                                    </UIField.LabelField>
                                    <UIField.LabelField
                                        label={"File size"}>
                                        {`${uploadDocumentState.fileField.value.size} bytes`}
                                    </UIField.LabelField>
                                    <UIField.LabelField
                                        label={"File type"}>
                                        {uploadDocumentState.fileField.value.type}
                                    </UIField.LabelField>
                                </ColumnLayout>
                                <br />
                                <UIField.StateValueField
                                    state={uploadDocumentState.descriptionField}
                                    name="Description"
                                    editing={true}
                                    variant="TextArea"
                                    constraintText={descriptionConstraint}
                                    placeholder="Enter a brief description..."
                                />
                                <UIField.CustomField
                                    child={
                                        <Select
                                            selectedOption={selectedDocumentGroup}
                                            onChange={(e) =>
                                                setSelectedDocumentGroup(e.detail.selectedOption)
                                            }
                                            options={[
                                                { label: "No group", value: "" },
                                                ...(props.state.contentField.value as DocumentContent)
                                                    .fileGroups
                                                    .map(g => Builder<SelectProps.Option>()
                                                        .label(g.groupTitle)
                                                        .value(g.groupTitle)
                                                        .build()
                                                    )]
                                            }
                                            selectedAriaLabel="Selected"
                                        />
                                    }
                                    name="Select File Group"
                                />
                                <br />
                                <Box float="right">
                                    <SpaceBetween direction="horizontal" size="s">
                                        <Button
                                            loading={
                                                getPresignedUrlRunner.status === "Running" ||
                                                uploadFileViaPresignedUrlRunner.status === "Running"
                                            }
                                            variant="link"
                                            onClick={onCancelUpload}>Cancel
                                        </Button>
                                        <Button
                                            loading={
                                                getPresignedUrlRunner.status === "Running" ||
                                                uploadFileViaPresignedUrlRunner.status === "Running"
                                            }
                                            variant="primary"
                                            disabled={uploadDocumentState.descriptionField.value.length > ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT}
                                            onClick={onUpload}>Upload
                                        </Button>
                                    </SpaceBetween>
                                </Box>
                            </Alert>
                        )}
                    </div>
                    {props.loading && <div className="vertical-center"><Spinner size="large" /> <em>Loading...</em></div>}
                    {(
                        !!props.state.contentField.value &&
                        !!(props.state.contentField.value as DocumentContent).files
                    ) &&
                        <React.Fragment>
                            <Grid gridDefinition={[
                                { colspan: 6 },
                                { colspan: 3 },
                                { colspan: 3 }
                            ]}>
                                <span><b>Document</b></span>
                                <span><b>Labels</b></span>
                                <span><b>Actions</b></span>
                            </Grid>
                            {(props.state.contentField.value as DocumentContent).files
                                .sort(!(props.state.contentField.value as DocumentContent)?.manualSort ?
                                        documentsComparator : undefined)
                                .map(file => (<DocumentContentComp
                                        document={file}
                                        loading={props.loading}
                                        reload={resourceIdOfUpdatedLabels === file.s3File.key}
                                        actions={compActionButtonDropdown(file)}
                                    />)
                                )
                            }
                        </React.Fragment>
                    }
                    <DocumentGroupExpandable
                        manualSort={(props.state.contentField.value as DocumentContent)?.manualSort}
                        fileGroups={
                            (
                                !!props.state.contentField.value &&
                                !!(props.state.contentField.value as DocumentContent).fileGroups
                            ) ?
                                (props.state.contentField.value as DocumentContent).fileGroups :
                                []
                        }
                        FileViewComponent={(params: { document: Document, groupTitle: string }) =>
                            <DocumentContentComp
                                document={params.document}
                                loading={props.loading}
                                reload={resourceIdOfUpdatedLabels === params.document.s3File.key}
                                groupTitle={params.groupTitle}
                                actions={compActionButtonDropdown(params.document, params.groupTitle)}
                            />
                        }
                    />
                </ColumnLayout>
            </SpaceBetween></React.Fragment>
    );
};

export const DocumentContentComp = (props: {
    document: Document;
    loading: boolean;
    reload: boolean;
    groupTitle?: string;
    actions: JSX.Element;
}) => {
    const context = React.useContext(AppContext);
    const getLabelsByResourceRunner = useAPI2(
        context.getLabelAPI().getLabelsByResource
    );
    const [labels, setLabels] = React.useState<Array<Label.Data>>([]);

    const invokeAPI = () => {
        getLabelsByResourceRunner.invoke(
            ServerSidePaginatedLoadingInput.create({
                partitionKey: S3File.AskLegalFileKey.toString(props.document.s3File.key),
                currentPageIndex: 1,
                pageSize: 100,
                filters: []
            })
        );
    };

    React.useEffect(() => {
        invokeAPI();
    }, [props.document]);

    React.useEffect(() => {
        if (getLabelsByResourceRunner.status !== "Running" && props.reload) {
            invokeAPI();
        }
    }, [props.reload]);

    React.useEffect(() => {
        if (getLabelsByResourceRunner.status === "Succeeded" && getLabelsByResourceRunner.output.totalCount > 0) {
            setLabels(getLabelsByResourceRunner.output.result);
        }
    }, [getLabelsByResourceRunner.status]);

    return <Grid gridDefinition={[
        { colspan: 6 },
        { colspan: 3 },
        { colspan: 3 }
    ]}>
        <div className="document-upload-flex">
            <DocumentIcon filename={props.document.filename} />
            <div className="document-info">
                <h6>{props.document.filename}</h6>
                <p>{props.document.description}</p>
            </div>
        </div>
        <div>
            {getLabelsByResourceRunner.status === "Running" && <Spinner />}
            {getLabelsByResourceRunner.status === "Succeeded" && labels.map(l => <Badge color="grey">{l.name}</Badge>)}
        </div>
        {props.actions}
    </Grid>;
};