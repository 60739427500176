import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { Spinner, Tabs } from "@amzn/awsui-components-react";
import { AdvancedListContent, Identity, Workflow } from "@amzn/ask-legal-domain";
import { AdvancedList } from "@amzn/altar-sds-client";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { Configuration } from "./advanced-list/sections/Configuration";
import { WorkflowList } from "./advanced-list/WorkflowList";
import { Permission } from "./advanced-list/sections/Permission";
import { ChangeSummary } from "./advanced-list/ChangeSummary";

enum AdvancedListTab {
    CONFIGURATION = "configuration",
    WORKFLOW = "workflow",
    PERMISSION = "permission"
}

export const AdvancedListContentEdit = (props: {
    state: UIModel.State<AdvancedListContent>;
}) => {
    const context = React.useContext(AppContext);
    const loadAdvancedListRunner = useAPI2(context.getAdvancedListAPI().load);
    const [advancedList, setAdvancedList] = React.useState<AdvancedList>();
    const [userIdentity, setUserIdentity] = React.useState<Identity>();
    const [activeTab, setActiveTab] = React.useState<AdvancedListTab>(AdvancedListTab.CONFIGURATION);

    const fetchIdentity = async () => {
        let currentUser: Identity;
        try {
            currentUser = await context.getIdentity();
        } catch (err) {
            console.warn(err);
        }
        if (!currentUser) {
            console.warn("Error fetching user");
            setUserIdentity(null);
        }
        setUserIdentity(currentUser);
    };

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    React.useEffect(() => {
        if (!userIdentity?.id || !props.state?.value?.entityRef) return;
        loadAdvancedListRunner.invoke({
            entityId: props.state.value.entityRef.entityId,
            repositoryId: props.state.value.entityRef.repositoryRef.repositoryId,
            by: {
                id: userIdentity.id,
                realm: "Amazon",
                type: "Person"
            }
        });
    }, [props.state?.value?.entityRef?.entityId, userIdentity]);

    React.useEffect(() => {
        if (loadAdvancedListRunner.output) {
            setAdvancedList(loadAdvancedListRunner.output.body);
        }
    }, [loadAdvancedListRunner.status]);

    return (
        <React.Fragment>
            {loadAdvancedListRunner.status === "Running" && <Spinner/>}
            {loadAdvancedListRunner.status !== "Running" && (<>
                <ChangeSummary content={props.state.value} advancedList={advancedList} />
                <Tabs
                    activeTabId={activeTab}
                    tabs={[
                        {
                            id: AdvancedListTab.CONFIGURATION,
                            label: "Configuration",
                            content: (
                                <>
                                    <Configuration state={props.state} advancedList={advancedList} />
                                </>
                            )
                        },
                        {
                            id: AdvancedListTab.WORKFLOW,
                            label: "Workflow",
                            disabled: !advancedList,
                            content: (
                                <>
                                    <WorkflowList
                                        state={props.state}
                                        advancedList={advancedList}
                                        by={{
                                            id: userIdentity?.id,
                                            realm: "Amazon",
                                            type: "Person"
                                        }}
                                        onCreated={(createdWorkflowPayload: Workflow.Payload) => {
                                            props.state.setValue(
                                                AdvancedListContent.upsertWorkflow({
                                                    content: props.state.value,
                                                    workflowPayload: createdWorkflowPayload
                                                })
                                            );
                                        }}
                                        onDeleted={(deletedWorkflowPayload: Workflow.Payload) => {
                                            props.state.setValue(
                                                AdvancedListContent.deleteWorkflow({
                                                    content: props.state.value,
                                                    workflowPayload: deletedWorkflowPayload
                                                })
                                            );
                                        }}
                                    />
                                    <br />
                                </>
                            )
                        },
                        {
                            id: AdvancedListTab.PERMISSION,
                            label: "Permission",
                            content: (
                                <>
                                    <Permission
                                        advancedList={advancedList}
                                        state={props.state}
                                    />
                                </>
                            )
                        }
                    ]}
                    onChange={({ detail }) => {
                        setActiveTab(detail.activeTabId as any as AdvancedListTab);
                    }}
                />
            </>)}
        </React.Fragment>
    );
};
