import * as React from "react";
import { CustomListContentView } from "../../container/view-content/CustomListContentView";
import { CustomListContent, CustomListObject } from "@amzn/ask-legal-domain";
import { QueryResultItem }  from "@aws-sdk/client-kendra";

export const LinkAnswers = (props: {
    results: QueryResultItem[]
}) => {

    const getCustomListContentFromKendraResult = (result: any[]): CustomListContent => {
        let contentList: CustomListObject[] = [];
        result.forEach(r => {
            contentList.push(CustomListObject.create(r.AdditionalAttributes[0].Value.TextWithHighlightsValue.Text, r.AdditionalAttributes[1].Value.TextWithHighlightsValue.Text));
        });
        return CustomListContent.create(contentList);
    };

    return (
        <React.Fragment>
            <h5>Related Quick Links</h5>
            <div style={
                { padding: "10px", borderRadius: "25px", border: "1px solid lightgray", backgroundColor: "whitesmoke", maxWidth: "350px" }
            }>
                <CustomListContentView content={getCustomListContentFromKendraResult(props.results)} />
            </div>
        </React.Fragment>
    );
};