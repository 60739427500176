import { DateTimeConstants } from "./date-time.constant";

export namespace DateTimeFormatter {
    export function formatDate(date: Date) {
        if (!date) return "";
        const d = new Date(date);
        return d.toLocaleDateString() + ", " + d.toLocaleTimeString();
    }

    export function toSimpleDate(date: string | Date, mode?: number) {
        if (!date) return "";
        if (!mode) mode = 0;
        const now = new Date();
        const d = new Date(date);
        return d.getDate() + " " +
            DateTimeConstants.MONTHS[d.getMonth() + 1][mode] +
            (d.getFullYear() === now.getFullYear() && mode === 0 ? "" : ", " + d.getFullYear());
    }

    export function padTwoDigits(num: number) {
        return num.toString().padStart(2, "0");
    }

    export function dateInYyyyMmDdHhMmSs(date: Date, dateDiveder: string = "-", dateTimeDiveder: string = "-", timeDiveder: string = ":") {
        return (
            [
                date.getFullYear(),
                padTwoDigits(date.getMonth() + 1),
                padTwoDigits(date.getDate()),
            ].join(dateDiveder)
            +
                dateTimeDiveder
            +
            [
                padTwoDigits(date.getHours()),
                padTwoDigits(date.getMinutes()),
                padTwoDigits(date.getSeconds()),
            ].join(timeDiveder)
        );
    }

    /**
     * Function to format date in supported format
     * @param value Date string in YYYY-MM-DD format
     * @param format Format string
     * @returns string
     */
    export function applyDateFormattingOnYYYYMMDD(value: string, format: string): string {
        const invalidResponse = "-";
        if (!value) {
            return invalidResponse;
        }

        const dateComponents = value.split(/[-/]/);
        if (dateComponents.length !== 3) {
            return invalidResponse;
        }

        const year = parseInt(dateComponents[0]);
        const month = parseInt(dateComponents[1]);
        const day = parseInt(dateComponents[2]);
        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            return invalidResponse;
        }

        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
            return invalidResponse;
        }

        switch (format) {
            case "YYYY-MM-DD":
                return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
            case "DD-MM-YYYY":
                return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
            case "MM-DD-YYYY":
                return `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
            case "DD/MM/YYYY":
                return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;
            case "MM/DD/YYYY":
                return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
            case "Mon D, YYYY":
                return `${DateTimeConstants.MONTHS[date.getMonth()][0]} ${date.getDate()}, ${date.getFullYear()}`;
            case "Month D, YYYY":
                return `${DateTimeConstants.MONTHS[date.getMonth()][1]} ${date.getDate()}, ${date.getFullYear()}`;
            default:
                return invalidResponse;
        }
    }
}