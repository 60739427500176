import * as React from "react";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { useServerSideCollection } from "../../hooks/legal-contacts-collection-hook";
import { LegalContactsPolarisFactory } from "../../factory/polaris/legal-contacts-polaris-factory";
import { ServerSideLegalContactsTable } from "./ServerSideLegalContactsTable";
import { Button, Flashbar, FlashbarProps, SpaceBetween } from "@amzn/awsui-components-react";
import { FreshnessState, ILegalContact } from "@amzn/ask-legal-domain";

export const MyLegalContactsTable = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const loadAllLegalContactsByUserApiRunner = useAPI2(
        context.getLegalContactAPI().loadAllByUser
    );
    const baselineContactApiRunner = useAPI2(
        context.getLegalContactAPI().update
    );
    const [flashbarItems, setFlashBarItems] = React.useState<Array<FlashbarProps.MessageDefinition>>([]);

    const collection = useServerSideCollection(
        loadAllLegalContactsByUserApiRunner,
        props.instanceId,
        {
            columnDefinitions: LegalContactsPolarisFactory.Table.toColumnDefinition(),
            defaultSortingColumn: LegalContactsPolarisFactory.DEFAULT_SORTING_STATE.sortingColumn,
            filteringProperties: LegalContactsPolarisFactory.Filters.PropertyFilters.toDefaultFilteringProperties(),
            selectionType: "single"
        }
    );

    const onBaselineUpdate = (updatedItem: ILegalContact.LoadAllLegalContactsByUserOutput) => {
        const updatedItemIndex = collection.tableProps.props.items.findIndex(x =>
            x.ref.entityRef.entityId === updatedItem.ref.entityRef.entityId
        );
        collection.tableProps.setProps((prev) => ({
            ...prev,
            items: [
                ...prev.items.slice(0, updatedItemIndex),
                updatedItem,
                ...prev.items.slice(updatedItemIndex + 1)
            ]
        }));
    };

    const baselineLegalContact = () => {
        if (collection.tableProps.props.selectedItems.length < 1) return;
        baselineContactApiRunner.invoke({
            containerId: collection.tableProps.props.selectedItems[0].containerId,
            entityVersionRef: collection.tableProps.props.selectedItems[0].ref,
            baselineFreshness: true
        });
    };

    const baselineAction = <Button
        disabled={collection.tableProps.props.selectedItems.length < 1}
        loading={baselineContactApiRunner.status === "Running"}
        onClick={baselineLegalContact}
        variant="primary"
    >
        Baseline Contact
    </Button>;

    React.useEffect(() => {
        if (baselineContactApiRunner.status === "Running") {
            setFlashBarItems([{
                type: "info",
                loading: true,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashBarItems([]),
                header: "Baseline contact in progress",
                content: "Please wait..."
            }]);
        } else if (baselineContactApiRunner.status === "Succeeded") {
            setFlashBarItems([{
                type: "success",
                loading: false,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashBarItems([]),
                header: "Baseline contact completed",
                content: "Your information has been saved"
            }]);
            onBaselineUpdate({
                ...baselineContactApiRunner.output,
                freshnessStatus: FreshnessState.FRESH
            });
        } else if (baselineContactApiRunner.status === "Error") {
            setFlashBarItems([{
                type: "error",
                loading: false,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashBarItems([]),
                header: "Baseline contact failed",
                content: baselineContactApiRunner.err.message
            }]);
        }
    }, [baselineContactApiRunner.status]);

    return <SpaceBetween size="s">
        <Flashbar
            items={flashbarItems}
            i18nStrings={{
                ariaLabel: "Notifications",
                notificationBarAriaLabel: "View all notifications",
                notificationBarText: "Notifications",
                errorIconAriaLabel: "Error",
                warningIconAriaLabel: "Warning",
                successIconAriaLabel: "Success",
                infoIconAriaLabel: "Info",
                inProgressIconAriaLabel: "In progress"
            }}
        />
        <ServerSideLegalContactsTable
            collection={collection}
            headerActions={baselineAction}
        />
    </SpaceBetween>;
};