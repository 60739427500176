export namespace Preference {
    export const KEY = "preference";

    export enum Lab {
        InPlaceContainerEditing = "In-place Container Editing",
        DevMode = "Development Mode",
        WMLSearch = "WMLSearch",
        LivePageUIRefresh = "LivePage UI Refresh"
    }
    export class Value {
        userId: string;
        instanceId: string;
        labs: Set<Lab>;
    }
}